import { Box, CssBaseline, StyledEngineProvider, ThemeProvider, Toolbar } from '@mui/material';

import { ErrorBoundary } from 'react-error-boundary';

import {
    Header,
    HeaderApplicationDescriptor,
    HeaderContextProvider,
    HeaderLogo,
    HeaderPalette,
    reactQueryHeaderPersister,
} from '@braincube/header';
import { AppsManager, AppsManagerFlat } from '@braincube/svg';
import { buildTheme } from '@braincube/theme';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import LoggerAndTrackerHandler from 'components/App/LoggerAndTrackerHandler';
import AppHeader from 'components/App/components/AppHeader';
import ProductSelector from 'components/ProductSelector';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { WebSocketProvider } from 'services/context/WebSocket';

import { NotificationProvider, SplashScreen } from '@braincube/ui';
import App from 'components/App';
import I18nWrapperProvider from 'components/App/I18nWrapperProvider';
import GlobalErrorBoundary from 'components/GlobalErrorBoundary';
import { HeaderWrapperProvider, useHeader } from 'services/hooks/useHeader';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function AppWrapper() {
    const { isFetched, user } = useHeader();

    return (
        <I18nWrapperProvider>
            <SplashScreen
                AppLogo={AppsManagerFlat}
                appName="Apps Manager"
                appVersion={import.meta.env.VITE_APP_VERSION}
                isContentLoaded={user && isFetched}
            >
                <NotificationProvider>
                    <LoggerAndTrackerHandler>
                        <WebSocketProvider>
                            <Header>
                                <HeaderPalette />
                                <HeaderLogo />
                                <ProductSelector />
                                <HeaderApplicationDescriptor
                                    logo={AppsManager}
                                    version={import.meta.env.VITE_APP_VERSION}
                                    title="Apps Manager"
                                />

                                <AppHeader />
                            </Header>

                            <Box sx={{ width: '100%', height: '100vh' }}>
                                <Toolbar />
                                <App />
                            </Box>
                        </WebSocketProvider>
                    </LoggerAndTrackerHandler>
                </NotificationProvider>
            </SplashScreen>
        </I18nWrapperProvider>
    );
}

const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');

script.async = false;
script.type = 'text/javascript';
if (import.meta.env.DEV) {
    script.src = `https://cdn.test.mybraincube.com/configuration.js?timestamp=${Date.now()}`;
} else {
    script.src = `https://${window.location.hostname.replace(
        'appsmanager',
        'cdn',
    )}/configuration.js?timestamp=${Date.now()}`;
}

const Init = () => (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: reactQueryHeaderPersister }}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={buildTheme()}>
                <CssBaseline />
                <ErrorBoundary FallbackComponent={GlobalErrorBoundary}>
                    <HeaderContextProvider>
                        <HeaderWrapperProvider>
                            <AppWrapper />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </HeaderWrapperProvider>
                    </HeaderContextProvider>
                </ErrorBoundary>
            </ThemeProvider>
        </StyledEngineProvider>
    </PersistQueryClientProvider>
);

script.onload = function render() {
    const container = document.getElementById('apps-manager-container');
    if (container) {
        const root = createRoot(container);
        root.render(<Init />);
    }
};

head.appendChild(script);
