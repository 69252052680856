import { useEffect } from 'react';

import Logger from '@braincube/logger';
import Tracker from '@braincube/tracker';

import { useHeader } from 'services/hooks/useHeader';

function LoggerAndTrackerHandler({ children }) {
    const { user, braincubeProduct, selectedSite } = useHeader();

    useEffect(() => {
        Logger.configure({
            sentry: import.meta.env.VITE_LOGGER_SENTRY === 'true',
            appName: 'app-store-front',
            version: import.meta.env.VITE_APP_VERSION,
            sentryProjectId: 23,
            sentryToken: 'a7651c098d974210a5934d39495b9d93',
        });
    }, []);

    useEffect(() => {
        if (braincubeProduct && selectedSite && user) {
            Tracker.configure({
                appName: 'app-store-front',
                appVersion: import.meta.env.VITE_APP_VERSION,
                apiName: braincubeProduct.name,
                matomoSiteId: '20',
                selectedSiteName: selectedSite.siteName,
                user,
            });
        }
    }, [braincubeProduct, selectedSite, user]);

    return children;
}

export default LoggerAndTrackerHandler;
