import { useI18n } from '@braincube/i18n';
import { Error as ErrorIcon, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Stack, Tooltip } from '@mui/material';
import BooleanField from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep/components/BooleanField';
import NumberField from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep/components/NumberField';
import ObjectField from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep/components/ObjectField';
import StringField from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep/components/StringField';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';

function PropertiesStep({ editableTemplate }) {
    const i18n = useI18n();
    const { errors, setFieldError } = useFormikContext();

    useEffect(() => {
        // Clear name and version errors when the user if they are present
        const keysToClear = ['name', 'version'];
        for (const key of keysToClear) {
            if (errors[key]) {
                setFieldError(key, undefined);
            }
        }
    }, [errors, setFieldError]);

    // Return an array containing the unique node ids of the properties in error
    const groupsInError = useMemo(() => {
        const groups = new Set();
        for (const errorKey of Object.keys(errors)) {
            const [groupId] = errorKey.split('/');
            groups.add(groupId);
        }
        return Array.from(groups);
    }, [errors]);

    return (
        <Box mt={2}>
            {editableTemplate.length === 0 && (
                <Alert severity="info">
                    {i18n.tc('digitalAssistantCreator.steps.properties.noCustomizableProperties')}
                </Alert>
            )}
            {editableTemplate.map((nodeStructure) => (
                <Accordion key={nodeStructure.id}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Stack spacing={1} direction="row">
                            <Tooltip title={i18n.tc('digitalAssistantCreator.steps.properties.errorOnGroup')}>
                                {groupsInError.includes(nodeStructure.id) && (
                                    <ErrorIcon color="error" data-testid="error-on-group-icon" />
                                )}
                            </Tooltip>
                            <span>{nodeStructure.label}</span>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2}>
                            {nodeStructure.properties.map((property) => {
                                switch (property.inputType) {
                                    case 'number':
                                        return <NumberField key={property.id} property={property} />;
                                    case 'boolean':
                                        return <BooleanField key={property.id} property={property} />;
                                    case 'object':
                                        return <ObjectField key={property.id} property={property} />;
                                    default:
                                        return <StringField key={property.id} property={property} />;
                                }
                            })}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}

PropertiesStep.propTypes = {
    editableTemplate: PropTypes.array.isRequired,
};

export default PropertiesStep;
