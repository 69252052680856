import { useI18n } from '@braincube/i18n';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    styled,
} from '@mui/material';
import DOMPurify from 'dompurify';
import React, { useCallback, useMemo, useState } from 'react';

import MediaStepper from 'components/Content/components/AppDetail/components/MediaStepper';
import Eula from 'components/Content/components/AvailableApplications/components/NotInstalledApplication/components/Eula';
import useProductOffers from 'components/Content/services/useProductOffers';
import { useSelectedApp } from 'services/context/SelectedAppProvider';
import { useWebSocket } from 'services/context/WebSocket';
import useApplicationMutation from 'services/hooks/react-query/useApplicationMutation';
import useGetInstalledApplicationsUuid from 'services/hooks/useGetInstalledApplicationsUuid';
import { useHeader } from 'services/hooks/useHeader';
import useIsAdmin from 'services/hooks/useIsAdmin';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
}));

const AppDetail = () => {
    const [shouldOpenEula, setShouldOpenEula] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const i18n = useI18n();
    const isAdmin = useIsAdmin();
    const { selectedProductUuid } = useHeader();
    const { selectedApp, setSelectedApp } = useSelectedApp();
    const { stompClient } = useWebSocket();

    const productOffers = useProductOffers();

    const appIsInOffer = useMemo(
        () => productOffers?.find((offer) => offer.apps.includes(selectedApp?.id)),
        [productOffers, selectedApp],
    );

    const installedApplicationsUuid = useGetInstalledApplicationsUuid();
    const { mutate: updateApplication } = useApplicationMutation();

    const installApp = useCallback(() => {
        updateApplication({ productUuid: selectedProductUuid, toInstallUuid: selectedApp.id, stompClient });
        setSelectedApp(null);
    }, [updateApplication, selectedProductUuid, selectedApp, setSelectedApp, stompClient]);

    const handleInstallation = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (selectedApp.eula && selectedApp.eula !== '') {
                setShouldOpenEula(true);
            } else {
                installApp();
            }
        },
        [installApp, selectedApp],
    );

    const confirmDelete = useCallback(() => setConfirmDeletion(true), []);
    const unconfirmDelete = useCallback(() => setConfirmDeletion(false), []);

    let buttons;

    if (installedApplicationsUuid.includes(selectedApp?.id)) {
        buttons = isAdmin && (
            <Button onClick={confirmDelete} color="inherit">
                {i18n.tc('dialog.remove')}
            </Button>
        );
    } else if (appIsInOffer && isAdmin) {
        buttons = (
            <Button onClick={handleInstallation} color="inherit">
                {i18n.tc('common.install')}
            </Button>
        );
    }

    const dangerouslySetInnerHTML = useMemo(
        () => ({ __html: DOMPurify.sanitize(selectedApp?.fulldescription) }),
        [selectedApp],
    );

    const closeEula = useCallback(() => setShouldOpenEula(false), []);

    const handleRemoveApplication = useCallback(() => {
        updateApplication({ productUuid: selectedProductUuid, toDeleteUuid: selectedApp.id, stompClient });
        setSelectedApp(null);
    }, [updateApplication, selectedApp, setSelectedApp, selectedProductUuid, stompClient]);

    if (!selectedApp) {
        return null;
    }

    return (
        <>
            <Dialog onClose={() => setSelectedApp(null)} open={selectedApp} maxWidth="sm" fullWidth>
                <DialogTitle disableTypography>
                    <Typography variant="h6">{selectedApp.name}</Typography>
                    <StyledIconButton aria-label="close" onClick={() => setSelectedApp(null)}>
                        <CloseIcon />
                    </StyledIconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedApp.fulldescription && (
                        // biome-ignore lint/security/noDangerouslySetInnerHtml: @TODO voir pour gérer cette exception
                        <Typography gutterBottom variant="body2" dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
                    )}
                    {selectedApp.mediaUrl && selectedApp.mediaUrl.length > 0 && (
                        <MediaStepper medias={selectedApp.mediaUrl} />
                    )}
                </DialogContent>
                <DialogActions>{buttons}</DialogActions>
            </Dialog>
            {shouldOpenEula && <Eula eula={selectedApp.eula} onClose={closeEula} onAccept={installApp} />}
            <Dialog open={confirmDeletion} onClose={unconfirmDelete}>
                <DialogTitle>{i18n.tc('dialog.areYouSure')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {selectedApp.name}
                        {i18n.tc('dialog.confirmDelete')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={unconfirmDelete} color="secondary">
                        {i18n.tc('dialog.no')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleRemoveApplication}>
                        {i18n.tc('dialog.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AppDetail;
