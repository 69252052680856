import { useMemo } from 'react';

import { useFeatureFlag } from '@braincube/feature-flag';
import { useHeader } from 'services/hooks/useHeader';

export function useFeatureAccess(featureFlagKey) {
    const { selectedSite, selectedProductUuid } = useHeader();

    const { data: hasAccess, isLoading: isFeatureFlagLoading } = useFeatureFlag(
        featureFlagKey,
        selectedSite.siteUuid,
        selectedProductUuid,
        import.meta.env.VITE_APP_PACKAGE_NAME,
    );
    return useMemo(
        () => ({
            hasAccess,
            isFeatureFlagLoading,
        }),
        [hasAccess, isFeatureFlagLoading],
    );
}

export default useFeatureAccess;
