import React from 'react';

import { headerLabels } from '@braincube/header';
import { I18nProvider, getLocaleKey } from '@braincube/i18n';
import { i18nLabels as uiLabels } from '@braincube/ui';

import { useHeader } from 'services/hooks/useHeader';
import localesMap from 'services/lang';

function I18nWrapperProvider({ children }) {
    const { locale } = useHeader();
    const localeKey = getLocaleKey(locale);

    return (
        <I18nProvider
            locale={localeKey}
            labels={{
                ...headerLabels.getLabels(localeKey),
                ...uiLabels.getLabels(localeKey),
                ...localesMap.getLabels(localeKey),
            }}
        >
            {children}
        </I18nProvider>
    );
}

export default I18nWrapperProvider;
