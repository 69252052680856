import { useQuery } from '@tanstack/react-query';
import { getDigitalAssistantTemplate } from 'services/api';
import { RQ_KEY_DA_TEMPLATE } from 'services/constants/reactQuery';

function useDigitalAssistantTemplate(productUuid, digitalAssistantTemplateId) {
    return useQuery({
        queryKey: [RQ_KEY_DA_TEMPLATE, { productUuid, digitalAssistantTemplateId }],
        queryFn: () => getDigitalAssistantTemplate(productUuid, digitalAssistantTemplateId),
        enabled: !!productUuid && !!digitalAssistantTemplateId,
    });
}

export default useDigitalAssistantTemplate;
