import { useI18n } from '@braincube/i18n';
import { TextField } from '@mui/material';
import fieldPropType from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep/services/constants';
import { useFormikContext } from 'formik';
import React, { useState, useCallback, useMemo } from 'react';

function ObjectField({ property }) {
    const i18n = useI18n();

    const safeStringify = useCallback((value) => {
        try {
            return JSON.stringify(value);
        } catch (e) {
            return '';
        }
    }, []);

    const { values, errors, setFieldValue, setFieldError } = useFormikContext();
    const [inputValue, setInputValue] = useState(safeStringify(values[property.id]));

    const handleChange = useCallback(
        (event) => {
            const { value } = event.target;
            setInputValue(value); // display modified changes
            try {
                let valueToSave = '';
                if (value) {
                    valueToSave = JSON.parse(value);
                }
                setFieldValue(`['${property.id}']`, valueToSave);
            } catch (e) {
                setFieldError(
                    `['${property.id}']`,
                    i18n.tc('digitalAssistantCreator.fields.editableObjectInput.error'),
                );
            }
        },
        [setFieldValue, setFieldError, property.id, i18n],
    );

    const sx = useMemo(() => ({ mt: 2 }), []);
    const placeholder = useMemo(() => safeStringify(property.default), [property.default, safeStringify]);

    return (
        <TextField
            sx={sx}
            fullWidth
            multiline
            name={`['${property.id}']`}
            label={property.label}
            placeholder={placeholder}
            required
            onChange={handleChange}
            value={inputValue}
            error={!!errors[property.id]}
            helperText={errors[property.id]}
        />
    );
}

ObjectField.propTypes = fieldPropType;

export default ObjectField;
