import { useQuery } from '@tanstack/react-query';
import { getUsers } from 'services/api';
import { RQ_KEY_USERS } from 'services/constants/reactQuery';

function useUsers() {
    return useQuery({
        queryKey: [RQ_KEY_USERS],
        queryFn: getUsers,
    });
}

export default useUsers;
