import { Typography, styled } from '@mui/material';
import React from 'react';

const StyledTitleWrapper = styled('div')(({ theme }) => ({
    margin: `${theme.spacing(2)} ${theme.spacing(2)}`,
}));

function SectionTitle({ title }) {
    return (
        <StyledTitleWrapper>
            <Typography variant="h5">{title}</Typography>
        </StyledTitleWrapper>
    );
}

export default SectionTitle;
