import { useQuery } from '@tanstack/react-query';
import { getApplicationVersions } from 'services/api';
import { RQ_KEY_APPLICATION_VERSIONS } from 'services/constants/reactQuery';

function useApplicationVersions(applicationId) {
    return useQuery({
        queryKey: [RQ_KEY_APPLICATION_VERSIONS, { applicationId }],
        queryFn: () => getApplicationVersions(applicationId),
        enabled: !!applicationId,
    });
}

export default useApplicationVersions;
