import { useI18n } from '@braincube/i18n';
import { EdgeFactorySupervision } from '@braincube/svg';
import { Apps, Update } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useCallback } from 'react';
import { useWebSocket } from 'services/context/WebSocket';
import useApplicationMutation from 'services/hooks/react-query/useApplicationMutation';
import useGetDeprecatedInstalledApplications from 'services/hooks/useGetDeprecatedInstalledApplications';
import { useHeader } from 'services/hooks/useHeader';
import useIsAdmin from 'services/hooks/useIsAdmin';

const Menu = () => {
    const i18n = useI18n();
    const isAdmin = useIsAdmin();
    const { user, selectedProductUuid } = useHeader();
    const { mutate: updateApplication } = useApplicationMutation();
    const deprecatedInstalledApplications = useGetDeprecatedInstalledApplications();
    const { stompClient } = useWebSocket();

    const admin = useCallback(() => {
        window.open(`https://admin.${window.BC_API_ENDPOINTS_CONF.base}/apps`);
    }, []);

    const supervision = useCallback(() => {
        window.open(`https://cdn.${window.BC_API_ENDPOINTS_CONF.base}/edge-factory-supervision/`);
    }, []);

    const triggerApplicationUpdate = useCallback(
        () =>
            updateApplication({
                productUuid: selectedProductUuid,
                toUpdateUuid: deprecatedInstalledApplications.map((app) => app.appId),
                stompClient,
            }),
        [deprecatedInstalledApplications, selectedProductUuid, updateApplication, stompClient],
    );

    return (
        <>
            {user.rootAdmin && (
                <ListItemButton onClick={admin}>
                    <ListItemIcon>
                        <Apps />
                    </ListItemIcon>
                    <ListItemText primary={i18n.tc('menu.appsAdministration')} />
                </ListItemButton>
            )}
            <ListItemButton onClick={supervision}>
                <ListItemIcon>
                    <EdgeFactorySupervision />
                </ListItemIcon>
                <ListItemText primary="IoT Server Supervision" />
            </ListItemButton>
            {isAdmin && deprecatedInstalledApplications && deprecatedInstalledApplications.length > 0 && (
                <ListItemButton onClick={triggerApplicationUpdate}>
                    <ListItemIcon>
                        <Update />
                    </ListItemIcon>
                    <ListItemText primary={i18n.tc('menu.updateApplications')} />
                </ListItemButton>
            )}
        </>
    );
};

export default Menu;
