import { styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const StyledRoot = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(145, 145, 145, 0.7)',
});

const NotReachableLayer = ({ message }) => <StyledRoot title={message} />;

NotReachableLayer.propTypes = {
    message: PropTypes.string.isRequired,
};

export default NotReachableLayer;
