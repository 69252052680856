import React, { createContext, useContext, useState } from 'react';

const SelectedAppContext = createContext(null);

export const SelectedAppProvider = ({ children }) => {
    const [selectedApp, setSelectedApp] = useState(null);

    const value = {
        selectedApp,
        setSelectedApp,
    };

    return <SelectedAppContext.Provider value={value}>{children}</SelectedAppContext.Provider>;
};

export const useSelectedApp = () => {
    return useContext(SelectedAppContext);
};
