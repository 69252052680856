import { Client } from '@stomp/stompjs';
import React, { createContext, useContext, useMemo, useState } from 'react';

const WebSocketContext = createContext(null);

function initStompClient() {
    const parameters = new URLSearchParams(window.location.search);
    const selectedProductUuid = parameters.get('productUuid');

    const client = new Client({
        brokerURL: window.Cypress
            ? 'wss://localhost:6969/websocket'
            : `wss://${window.BC_API_ENDPOINTS_CONF.appsManager}/websocket`,
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
        if (selectedProductUuid) {
            client.subscribe(`/topic/${selectedProductUuid}`);
        }
    };

    client.onStompError = (frame) => {
        console.log(`Broker reported error: ${frame.headers.message}`);
        console.log(`Additional details: ${frame.body}`);
    };

    client.activate();
    return client;
}

export const WebSocketProvider = ({ children }) => {
    const [subscription, setSubscription] = useState(null);

    const stompClient = useMemo(() => initStompClient(), []);

    const value = useMemo(
        () => ({
            stompClient,
            subscription,
            setSubscription,
        }),
        [stompClient, subscription],
    );

    return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
