import { useQuery } from '@tanstack/react-query';
import { getDigitalAssistant } from 'services/api';
import { RQ_KEY_DA } from 'services/constants/reactQuery';

function useDigitalAssistant(productUuid, digitalAssistantId) {
    return useQuery({
        queryKey: [RQ_KEY_DA, { productUuid, digitalAssistantId }],
        queryFn: () => getDigitalAssistant(productUuid, digitalAssistantId),
        enabled: !!productUuid && !!digitalAssistantId,
    });
}

export default useDigitalAssistant;
