import { useMutation } from '@tanstack/react-query';
import { updateApplicationPermissions } from 'services/api';
import { RQ_KEY_APP_PERMISSIONS } from 'services/constants/reactQuery';

function useApplicationPermissionsMutation() {
    return useMutation({
        mutationFn: updateApplicationPermissions,
        onMutate: async ({ applicationId, productUuid, share }) => {
            await queryClient.cancelQueries({ queryKey: [RQ_KEY_APP_PERMISSIONS, { applicationId, productUuid }] });

            const previousCache = queryClient.getQueryData([RQ_KEY_APP_PERMISSIONS, { applicationId, productUuid }]);

            // @TODO: A vérifier lors de l'utilisation du hook
            queryClient.setQueryData([RQ_KEY_APP_PERMISSIONS, { applicationId, productUuid }], () => {
                return share;
            });

            return { previousCache };
        },
    });
}

export default useApplicationPermissionsMutation;
