import { useI18n } from '@braincube/i18n';
import { DialogContent, Stepper as MuiStepper, Step, StepButton } from '@mui/material';
import PropertiesStep from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep';
import PublishStep from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PublishStep';
import StepperActions from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/StepperActions';
import { useApplicationTemplate } from 'components/App/components/AppHeader/components/DigitalAssistantCreator/context';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { boolean, mixed, number, object, string } from 'yup';

import { useNotification } from '@braincube/ui';

function Stepper({ onPrev }) {
    const i18n = useI18n();
    const { enqueueError, enqueueSuccess } = useNotification();

    const { buildFile } = useApplicationTemplate();

    const [currentStep, setCurrentStep] = useState(0);
    const [isPublishing, setIsPublishing] = useState(false);

    // Return an array of node structures (id, label, properties)
    const editableTemplate = useMemo(() => buildFile?.template || [], [buildFile]);

    // Return an array of all properties that can be edited
    const editableProperties = useMemo(() => {
        return editableTemplate.flatMap((nodeStructure) => nodeStructure.properties);
    }, [editableTemplate]);

    const steps = useMemo(
        () => [
            i18n.tc('digitalAssistantCreator.steps.properties.label'),
            i18n.tc('digitalAssistantCreator.steps.publish.label'),
        ],
        [i18n],
    );

    const handleBack = useCallback(() => setCurrentStep((prevStep) => prevStep - 1), []);

    const handleNext = useCallback(() => setCurrentStep((prevStep) => prevStep + 1), []);

    const initialValues = useMemo(() => {
        const values = {
            name: '',
            version: '',
        };

        for (const property of editableProperties) {
            values[property.id] = property.default;
        }

        return values;
    }, [editableProperties]);

    const handlePublish = useCallback(
        (values) => {
            try {
                setIsPublishing(true);

                const {
                    id: buildId,
                    name: buildName,
                    version: buildVersion,
                    versionId: buildVersionId,
                    ...buildFileData
                } = buildFile;
                const { name: appName, version: appVersion, ...confValues } = values;
                const configuration = {
                    application_uid: uuidv4(),
                };

                // If a field is empty, set it to the default value
                for (const key of Object.keys(confValues)) {
                    configuration[key] = confValues[key] || initialValues[key];
                }

                // Find and modify the label property in the tab item (label which appears in the tab on the node-red editor)
                const updatedFlow = buildFile.flow.map((item) => {
                    if (item.type === 'tab') {
                        return {
                            ...item,
                            label: appName, // Change this to the expected new label
                            locked: true, // will make the flow disabled on node-red editor
                        };
                    }
                    return item;
                });

                const appToPublish = JSON.stringify({
                    ...buildFileData,
                    flow: updatedFlow,
                    name: appName,
                    version: appVersion,
                    fromTemplate: {
                        id: buildId,
                        name: buildName,
                        version: buildVersion,
                        versionId: buildVersionId,
                    },
                    configuration,
                });

                // Create Blob object from json content
                const blob = new Blob([appToPublish], { type: 'application/json' });
                const url = URL.createObjectURL(blob);

                // Create a fake link element to simulate click and download file
                const a = document.createElement('a');
                a.href = url;
                a.download = `${appName}.json`;
                document.body.appendChild(a);
                a.click();

                // Clear the URL object
                URL.revokeObjectURL(url);

                setIsPublishing(false);
                enqueueSuccess(i18n.tc('digitalAssistantCreator.notistack.publish.success'));
            } catch (err) {
                enqueueError(i18n.tc('digitalAssistantCreator.notistack.publish.error'));
            }
        },
        [buildFile, initialValues, enqueueError, enqueueSuccess, i18n],
    );

    const validationSchema = useMemo(() => {
        const schemas = {};

        if (currentStep === 1) {
            schemas.name = string().required(i18n.tc('yup.required'));
            schemas.version = string().required(i18n.tc('yup.required'));
        }

        for (const property of editableProperties) {
            let validator = string().required(i18n.tc('yup.required'));

            if (property.inputType === 'boolean') {
                validator = boolean();
            } else if (property.inputType === 'number') {
                validator = number().required(i18n.tc('yup.required'));
            } else if (property.inputType === 'object') {
                validator = mixed()
                    .test(
                        'is-object-or-array',
                        i18n.tc('digitalAssistantCreator.fields.editableObjectInput.error'),
                        (value) => !value || typeof value === 'object' || Array.isArray(value),
                    )
                    .required(i18n.tc('yup.required'));
            }

            schemas[property.id] = validator;
        }

        return object(schemas);
    }, [editableProperties, currentStep, i18n]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            validateOnMount
            onSubmit={handlePublish}
        >
            <Form>
                <DialogContent>
                    <MuiStepper activeStep={currentStep} alternativeLabel>
                        {steps.map((label, index) => (
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            <Step key={`step-${index}`}>
                                <StepButton onClick={() => setCurrentStep(index)}>{label}</StepButton>
                            </Step>
                        ))}
                    </MuiStepper>

                    {currentStep === 0 && <PropertiesStep editableTemplate={editableTemplate} />}
                    {currentStep === 1 && <PublishStep isPublishing={isPublishing} />}
                </DialogContent>

                <StepperActions currentStep={currentStep} onPrev={onPrev} onBack={handleBack} onNext={handleNext} />
            </Form>
        </Formik>
    );
}

Stepper.propTypes = {
    onPrev: PropTypes.func.isRequired,
};

export default Stepper;
