import { useI18n } from '@braincube/i18n';
import { Search } from '@braincube/ui';
import { FormControl, Grid, InputLabel, MenuItem, Select, styled } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { DA_CATEGORY } from 'services/constants/categories';
import { useFilters } from 'services/context/Filters';

import useFeatureAccess from 'services/hooks/useFeatureAccess';
import useCategoriesList from './services/useCategoriesList';

const StyledContentSearch = styled('div')(({ theme }) => ({
    position: 'fixed',
    zIndex: 1,
    top: `${theme.header.height}px`,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1),
}));

const Filters = () => {
    const i18n = useI18n();
    const categoriesList = useCategoriesList();
    const { hasAccess: hasAccessToDigitalAssistant = false } = useFeatureAccess('appStore.digitalAssistantAvailable');
    const { textFilter, setTextFilter, categoryFilter, setCategoryFilter } = useFilters();

    const allCategories = useMemo(() => {
        const finalCategories = [...categoriesList];

        if (hasAccessToDigitalAssistant) {
            finalCategories.push(DA_CATEGORY);
        }

        return finalCategories;
    }, [categoriesList, hasAccessToDigitalAssistant]);

    const triggerCategoryChange = useCallback((event) => setCategoryFilter(event.target.value), [setCategoryFilter]);

    return (
        <StyledContentSearch>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} md={2}>
                    <Search
                        onChange={setTextFilter}
                        searchValue={textFilter}
                        label={i18n.tc('common.search')}
                        minChars={0}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <FormControl fullWidth>
                        <InputLabel>{i18n.tc('search.category')}</InputLabel>
                        <Select
                            label={i18n.tc('search.category')}
                            value={categoryFilter}
                            onChange={triggerCategoryChange}
                            size="small"
                        >
                            <MenuItem value="all">All</MenuItem>
                            {allCategories.map((category) => (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </StyledContentSearch>
    );
};

export default Filters;
