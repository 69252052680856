import { useI18n } from '@braincube/i18n';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { AccessPermissions } from '@braincube/ui';
import useApplicationPermissions from 'services/hooks/react-query/useApplicationPermissions';
import useApplicationPermissionsMutation from 'services/hooks/react-query/useApplicationPermissionsMutation';
import useUsers from 'services/hooks/react-query/useUsers';
import { useHeader } from 'services/hooks/useHeader';

const profiles = [
    {
        label: 'Iplwadmin',
        value: 'IPLWADMIN',
    },
    {
        label: 'Coach',
        value: 'COACH',
    },
    {
        label: 'Administrator',
        value: 'ADMIN',
    },
    {
        label: 'Manager',
        value: 'MANAGER',
    },
    {
        label: 'Expert',
        value: 'EXPERT',
    },
    {
        label: 'User',
        value: 'USER',
    },
    {
        label: 'Custom',
        value: 'CUSTOM',
    },
];

const StyledLoaderWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-scrollPaper': {
        overflow: 'visible',
    },
});

const StyledDialogContent = styled(DialogContent)({
    overflow: 'visible',
});

const ShareDialog = ({ onClose, app }) => {
    const i18n = useI18n();
    const { accessList, selectedProductUuid } = useHeader();

    const { mutate: updateApplicationPermissions } = useApplicationPermissionsMutation();
    const {
        data: applicationPermissions,
        isLoading: isLoadingPermissions,
        isSuccess: isApplicationPermissionsSuccess,
    } = useApplicationPermissions(app.id, selectedProductUuid);
    const { data: usersList, isLoading: isLoadingUsers, isSuccess: isUsersSuccess } = useUsers();

    const initialInfos = useMemo(
        () => ({
            ...applicationPermissions,
            usersUuid: applicationPermissions?.usersUuid || [],
        }),
        [applicationPermissions],
    );

    const [infos, setInfos] = useState(initialInfos);

    const initalProfile = useMemo(() => {
        if (!infos?.controlledLevel && infos?.shareLevel === 'PRIVATE') {
            return 'private';
        }
        const appProfile = profiles.find((profile) => profile.value === infos?.controlledLevel);

        return appProfile?.value.toLowerCase() || 'public';
    }, [infos]);

    const [selectedProfile, setSelectedProfile] = useState(initalProfile);

    const users = useMemo(() => {
        if (!usersList) {
            return [];
        }

        return accessList.map((access) => ({
            ...usersList?.find((user) => user.uuid === access.user?.uuid),
            profile: access.accessType?.toLowerCase(),
        }));
    }, [accessList, usersList]);

    const currentProfileAccess = useMemo(() => {
        return accessList.find(({ product }) => product.productId === selectedProductUuid).accessRight.toLowerCase();
    }, [accessList, selectedProductUuid]);

    const validate = useCallback(() => {
        let controlledLevel = selectedProfile.toUpperCase();
        let shareLevel = selectedProfile.toUpperCase() !== 'PUBLIC' ? 'CONTROLLED' : 'PUBLIC';

        if (selectedProfile.toUpperCase() === 'PRIVATE') {
            controlledLevel = null;
            shareLevel = 'PRIVATE';
        }

        updateApplicationPermissions(
            {
                applicationId: app.id,
                productUuid: selectedProductUuid,
                share: {
                    ...infos,
                    controlledLevel,
                    shareLevel,
                },
            },
            {
                onSuccess: () => {
                    onClose();
                },
            },
        );
    }, [app.id, infos, onClose, selectedProductUuid, selectedProfile, updateApplicationPermissions]);

    const onSelectedUsersChange = useCallback(
        (selectedUsers) => {
            setInfos({
                ...infos,
                usersUuid:
                    selectedUsers?.map((user) => {
                        return users.find((userFromList) => userFromList.email === user.email).uuid;
                    }) || [],
            });
        },
        [infos, users],
    );

    const onSelectedProfileChange = useCallback((profile) => {
        setSelectedProfile(profile);
    }, []);

    const isFetching = useMemo(() => isLoadingPermissions || isLoadingUsers, [isLoadingPermissions, isLoadingUsers]);
    const isFetchingSuccess = useMemo(
        () => isApplicationPermissionsSuccess && isUsersSuccess,
        [isApplicationPermissionsSuccess, isUsersSuccess],
    );

    return (
        <StyledDialog open onClose={onClose} fullWidth>
            <StyledDialogContent>
                {isFetching && (
                    <StyledLoaderWrapper>
                        <CircularProgress />
                        <Typography paragraph>{i18n.tc('dialog.loading')}</Typography>
                    </StyledLoaderWrapper>
                )}

                {isFetchingSuccess && users && infos && (
                    <AccessPermissions
                        currentProfile={currentProfileAccess}
                        users={users.map((user) => ({
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            profile: user.profile,
                        }))}
                        selectedUsers={users
                            .filter((user) => infos.usersUuid.includes(user.uuid))
                            .map((user) => ({
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email,
                                profile: user.profile,
                            }))}
                        onSelectedUsersChange={onSelectedUsersChange}
                        selectedProfile={
                            typeof selectedProfile === 'string' ? selectedProfile : selectedProfile?.value.toLowerCase()
                        }
                        onSelectedProfileChange={onSelectedProfileChange}
                    />
                )}
            </StyledDialogContent>
            {isFetchingSuccess && (
                <DialogActions>
                    <Button onClick={validate} color="inherit">
                        {i18n.tc('common.save')}
                    </Button>
                </DialogActions>
            )}
        </StyledDialog>
    );
};

ShareDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    app: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default ShareDialog;
