import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteDigitalAssistant } from 'services/api';
import { RQ_KEY_INSTALLED_DA, RQ_KEY_PRODUCT_INFO } from 'services/constants/reactQuery';

function useDigitalAssistantMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ productUuid, digitalAssistantId, stompClient }) => {
            return deleteDigitalAssistant(productUuid, digitalAssistantId);
        },
        onMutate: async ({ productUuid, stompClient, digitalAssistantId }) => {
            // Cancel any outgoing refetches for the installed DA query to avoid race conditions
            await queryClient.cancelQueries({ queryKey: [RQ_KEY_INSTALLED_DA, { productUuid }] });

            // Get the current cache for the installed DA query
            const previousCache = queryClient.getQueryData([RQ_KEY_INSTALLED_DA, { productUuid }]);

            // Optimistically update the cache to reflect the loading state of the DA being mutated
            queryClient.setQueryData([RQ_KEY_INSTALLED_DA, { productUuid }], (oldData) => {
                if (oldData) {
                    // Remove the current application from the old data
                    const newData = oldData.filter((data) => data.id !== digitalAssistantId);

                    // Find the current application and set its loading state to true
                    let monApp = oldData.find((data) => data.id === digitalAssistantId);
                    monApp = {
                        ...monApp,
                        isLoading: true,
                    };

                    // Add the updated application back to the data
                    newData.push(monApp);

                    return newData;
                }
            });

            // Subscribe to the WebSocket topic for the product to receive updates
            stompClient?.subscribe(`/topic/${productUuid}`, (message) => {
                // Invalidate the queries for product info and applications to refetch the latest data
                if (message) {
                    queryClient.invalidateQueries({ queryKey: [RQ_KEY_PRODUCT_INFO, { productUuid }] });
                    queryClient.invalidateQueries({ queryKey: [RQ_KEY_INSTALLED_DA, { productUuid }] });
                }
            });

            return { previousCache };
        },
    });
}

export default useDigitalAssistantMutation;
