import { createContext, useContext, useMemo, useState } from 'react';

import { useBranding, useMe, useSites, useUserPreferences } from '@braincube/header';

const HeaderWrapperContext = createContext(null);

export function HeaderWrapperProvider({ children }) {
    const { selectedSite, braincubeProduct } = useSites();
    const {
        data: { accessList, user },
    } = useMe();

    const {
        data: {
            general: { locale },
        },
    } = useUserPreferences();
    const { isFetched } = useBranding();
    const [selectedProduct, setSelectedProduct] = useState(braincubeProduct ?? null);

    const value = useMemo(() => {
        return {
            accessList,
            braincubeProduct: selectedProduct,
            isFetched,
            locale,
            selectedProductUuid: selectedProduct?.productId,
            selectedProductName: selectedProduct?.name,
            selectedSite,
            setSelectedProduct,
            user,
        };
    }, [accessList, isFetched, locale, selectedProduct, selectedSite, user]);

    return <HeaderWrapperContext.Provider value={value}>{children}</HeaderWrapperContext.Provider>;
}

export function useHeader() {
    return useContext(HeaderWrapperContext);
}
