import React, { createContext, useContext, useState } from 'react';

const FiltersContext = createContext(null);

export const FiltersProvider = ({ children }) => {
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [textFilter, setTextFilter] = useState('');

    const value = {
        categoryFilter,
        setCategoryFilter,
        textFilter,
        setTextFilter,
    };

    return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

export const useFilters = () => {
    return useContext(FiltersContext);
};
