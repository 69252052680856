import { Box, CircularProgress, Dialog, DialogTitle } from '@mui/material';
import BuildPaste from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/BuildPaste';
import Stepper from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import useDigitalAssistant from 'services/hooks/react-query/useDigitalAssistant';
import useDigitalAssistantTemplate from 'services/hooks/react-query/useDigitalAssistantTemplate';
import { useHeader } from 'services/hooks/useHeader';
import { useApplicationTemplate } from './context';

function DigitalAssistantCreator({ opened, onClose, selectedDigitalAssistant }) {
    const { setBuildFile } = useApplicationTemplate();
    const [displayStepper, setDisplayStepper] = useState(!!selectedDigitalAssistant);
    const { selectedProductUuid } = useHeader();
    const { data: digitalAssistantTemplate } = useDigitalAssistantTemplate(
        selectedProductUuid,
        selectedDigitalAssistant?.id,
    );
    const { data: digitalAssistant, isLoading } = useDigitalAssistant(
        selectedProductUuid,
        selectedDigitalAssistant?.id,
    );

    const doClose = useCallback(() => {
        onClose();

        // We use a timeout to avoid the stepper to be hidden during the closing animation
        setTimeout(() => {
            setBuildFile('');
            setDisplayStepper(false);
        }, 250);
    }, [onClose, setBuildFile]);

    const onCancel = useCallback(() => {
        doClose();

        // @TODO : Reset form logic here
    }, [doClose]);

    const onConfirmBuildPaste = useCallback(() => {
        setDisplayStepper(true);
    }, []);

    const hideStepper = useCallback(() => {
        setDisplayStepper(false);
    }, []);

    useEffect(() => {
        if (digitalAssistant) {
            setBuildFile(digitalAssistant);
        } else if (digitalAssistantTemplate) {
            setBuildFile(digitalAssistantTemplate);
        }
    }, [setBuildFile, digitalAssistant, digitalAssistantTemplate]);

    return (
        <Dialog open={opened} onClose={doClose} fullWidth maxWidth={500}>
            <DialogTitle>Digital Assistant Creator</DialogTitle>

            {isLoading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 8 }}>
                    <CircularProgress />
                </Box>
            ) : displayStepper ? (
                <Stepper onPrev={hideStepper} />
            ) : (
                <BuildPaste onConfirm={onConfirmBuildPaste} onCancel={onCancel} />
            )}
        </Dialog>
    );
}

DigitalAssistantCreator.propTypes = {
    opened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedDigitalAssistant: PropTypes.object.isRequired,
};

export default DigitalAssistantCreator;
