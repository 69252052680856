import {
    Button,
    Card,
    CardActionArea,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import { useI18n } from '@braincube/i18n';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import DigitalAssistantCreator from 'components/App/components/AppHeader/components/DigitalAssistantCreator';
import { DigitalAssistantCreatorContextProvider } from 'components/App/components/AppHeader/components/DigitalAssistantCreator/context';
import { useWebSocket } from 'services/context/WebSocket';
import { getAppIcon } from 'services/helpers';
import useDigitalAssistantMutation from 'services/hooks/react-query/useDigitalAssistantMutation';
import { useHeader } from 'services/hooks/useHeader';

const StyledPending = styled('div')(({ theme }) => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
}));

const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'backgroundColor' })(({ backgroundColor }) => ({
    backgroundColor,
    position: 'relative',
    '&:hover': {
        '& .quick-actions': {
            opacity: 1,
        },
    },
    height: 150,
}));

const StyledCardActionArea = styled(CardActionArea)({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    '&:hover': {
        '& .quick-actions': {
            opacity: 1,
        },
    },
});

const StyledCardAppIcon = styled('div')({
    display: 'flex',
    height: '60%',
    alignItems: 'center',
    fontSize: 60,
});

const StyledCardAppName = styled('div', { shouldForwardProp: (prop) => prop !== 'color' })(({ color }) => ({
    color,
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    height: '40%',
    alignItems: 'center',
}));

const StyledQuickActions = styled('div')({
    display: 'block',
    top: 0,
    right: 0,
    position: 'absolute',
    width: 35,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    opacity: 0,
    transition: 'ease-in 0.2s',
});

const StyledAction = styled('div')(({ theme }) => ({
    width: 35,
    height: 35,
    marginBottom: 1,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

function DigitalAssistant({ digitalAssistant }) {
    const theme = useTheme();
    const i18n = useI18n();
    const { selectedProductUuid } = useHeader();
    const { mutate: deleteDigitalAssistant } = useDigitalAssistantMutation();
    const { stompClient } = useWebSocket();

    const [digitalAssistantCreatorOpened, setDigitalAssistantCreatorOpened] = useState(false);
    const [deletionDialogOpened, setDeletionDialogOpened] = useState(false);

    const openDigitalAssistantCreator = useCallback(() => setDigitalAssistantCreatorOpened(true), []);

    const closeDigitalAssistantCreator = useCallback(() => setDigitalAssistantCreatorOpened(false), []);

    const openConfirmDeletionDialog = useCallback((e) => {
        setDeletionDialogOpened(true);
    }, []);

    const closeConfirmDialogDelete = useCallback(() => setDeletionDialogOpened(false), []);

    const triggerRemoveDigitalAssistant = useCallback(() => {
        deleteDigitalAssistant({
            productUuid: selectedProductUuid,
            digitalAssistantId: digitalAssistant.id,
            stompClient,
        });
        closeConfirmDialogDelete();
    }, [digitalAssistant, closeConfirmDialogDelete, deleteDigitalAssistant, selectedProductUuid, stompClient]);

    return (
        <Grid item xs={6} sm={4} lg={2}>
            <StyledCard backgroundColor={digitalAssistant.color}>
                <StyledCardActionArea>
                    <StyledCardAppIcon>
                        {getAppIcon(digitalAssistant.icon, theme.palette.getContrastText(digitalAssistant.color), 60)}
                    </StyledCardAppIcon>
                    <StyledCardAppName color={theme.palette.getContrastText(digitalAssistant.color)}>
                        <Typography variant="caption" data-testid="installed-application-name">
                            {digitalAssistant.name}
                        </Typography>
                        {digitalAssistant?.isLoading && (
                            <StyledPending>
                                <CircularProgress size={24} color="inherit" />
                            </StyledPending>
                        )}
                    </StyledCardAppName>
                </StyledCardActionArea>
                <StyledQuickActions className="quick-actions">
                    <Tooltip title={i18n.tc('common.edit')} disableInteractive>
                        <StyledAction tabIndex={0} onClick={openDigitalAssistantCreator}>
                            <EditIcon htmlColor="#FFF" />
                        </StyledAction>
                    </Tooltip>

                    <Tooltip title={i18n.tc('card.tooltip.delete')} disableInteractive>
                        <StyledAction tabIndex={0} onClick={openConfirmDeletionDialog}>
                            <DeleteForeverIcon htmlColor="#FFF" />
                        </StyledAction>
                    </Tooltip>
                </StyledQuickActions>
            </StyledCard>
            <DigitalAssistantCreatorContextProvider>
                {digitalAssistantCreatorOpened && (
                    <DigitalAssistantCreator
                        opened={digitalAssistantCreatorOpened}
                        onClose={closeDigitalAssistantCreator}
                        selectedDigitalAssistant={digitalAssistant}
                    />
                )}
            </DigitalAssistantCreatorContextProvider>
            <Dialog open={deletionDialogOpened} onClose={closeConfirmDialogDelete}>
                <DialogTitle>{i18n.tc('dialog.areYouSure')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {digitalAssistant.name}
                        {i18n.tc('dialog.confirmDelete')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={closeConfirmDialogDelete} color="secondary">
                        {i18n.tc('dialog.no')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={triggerRemoveDigitalAssistant}>
                        {i18n.tc('dialog.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default DigitalAssistant;
