import { useMemo } from 'react';

import useProductInfo from 'services/hooks/react-query/useProductInfo';
import { useHeader } from 'services/hooks/useHeader';

export default function useGetDeprecatedInstalledApplications() {
    const { selectedProductUuid } = useHeader();
    const { data: productInfo } = useProductInfo(selectedProductUuid);

    return useMemo(() => productInfo?.apps.filter((app) => app.latest === false), [productInfo]);
}
