import { createContext, useContext, useMemo } from 'react';

import { useBranding, useMe, useSites, useUserPreferences } from '@braincube/header';

const HeaderWrapperContext = createContext(null);

export function HeaderWrapperProvider({ children }) {
    const { selectedSite, braincubeProduct, setBraincubeProduct } = useSites();
    const {
        data: { accessList, user },
    } = useMe();

    const {
        data: {
            general: { locale },
        },
    } = useUserPreferences();
    const { isFetched } = useBranding();

    const value = useMemo(() => {
        return {
            accessList,
            braincubeProduct: braincubeProduct,
            isFetched,
            locale,
            selectedProductUuid: braincubeProduct?.productId,
            selectedProductName: braincubeProduct?.name,
            selectedSite,
            setSelectedProduct: setBraincubeProduct,
            user,
        };
    }, [accessList, isFetched, locale, braincubeProduct, selectedSite, user, setBraincubeProduct]);

    return <HeaderWrapperContext.Provider value={value}>{children}</HeaderWrapperContext.Provider>;
}

export function useHeader() {
    return useContext(HeaderWrapperContext);
}
