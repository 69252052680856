import { useI18n } from '@braincube/i18n';
import { Card, CardActionArea, CardContent, CircularProgress, Grid, Typography, styled, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import NotReachableLayer from 'components/NotReachableLayer';
import { ReactComponent as InstallIcon } from 'images/install.svg';
import iotNode from 'images/ioTNode.svg';
import { useSelectedApp } from 'services/context/SelectedAppProvider';
import { useWebSocket } from 'services/context/WebSocket';
import { getAppIcon } from 'services/helpers';
import useApplicationMutation from 'services/hooks/react-query/useApplicationMutation';
import { useHeader } from 'services/hooks/useHeader';
import useIsAdmin from 'services/hooks/useIsAdmin';

import Eula from './components/Eula';

const StyledPending = styled('div')(({ theme }) => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
}));

const StyledQuickActions = styled('div')({
    display: 'block',
    top: 0,
    right: 0,
    position: 'absolute',
    width: 45,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    transition: 'ease-in 0.2s',
    opacity: 0,
});

const StyledCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    '&:hover': {
        '& .quick-actions': {
            opacity: 1,
        },
    },
    height: '100%',
    marginBottom: theme.spacing(1),
}));

const StyledCardActionArea = styled(CardActionArea)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
});

const StyledCardMedia = styled('div', { shouldForwardProp: (prop) => prop !== 'color' })(({ color }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 120,
    fontSize: 60,
    backgroundColor: color,
}));

const StyledIotNote = styled('div')({
    position: 'absolute',
    left: 0,
    top: 0,
    width: 34,
    height: 34,
    opacity: 0.4,
    backgroundImage: `url(${iotNode})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 20,
});

const StyledCardContent = styled(CardContent)({
    flex: 1,
    width: '100%',
});

const StyledActionInstall = styled('div')(({ theme }) => ({
    width: 45,
    height: 45,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const NotInstalledApplicationComponent = ({ app, isNotInOffer }) => {
    const theme = useTheme();
    const [shouldOpenEula, setShouldOpenEula] = useState(false);
    const i18n = useI18n();
    const { selectedProductUuid } = useHeader();
    const isAdmin = useIsAdmin();
    const { mutate: updateApplication } = useApplicationMutation();
    const { setSelectedApp } = useSelectedApp();
    const { stompClient } = useWebSocket();

    const handleInstallation = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            if (app.eula && app.eula !== '') {
                setShouldOpenEula(true);
            } else {
                updateApplication({ productUuid: selectedProductUuid, toInstallUuid: app.id, stompClient });
            }
        },
        [app, updateApplication, selectedProductUuid, stompClient],
    );

    const handleSelectApp = useCallback(() => setSelectedApp(app), [app, setSelectedApp]);

    const closeEula = useCallback(() => setShouldOpenEula(false), []);

    const acceptEula = useCallback(
        () => updateApplication({ productUuid: selectedProductUuid, toInstallUuid: app.id, stompClient }),
        [app.id, updateApplication, selectedProductUuid, stompClient],
    );

    return (
        <>
            <Grid item xs={6} sm={4} lg={2}>
                <StyledCard onClick={handleSelectApp}>
                    <StyledCardActionArea>
                        <StyledCardMedia color={app.color}>
                            {getAppIcon(app.icon, theme.palette.getContrastText(app.color))}
                            {app.productsTarget.includes('IOT') && <StyledIotNote />}
                        </StyledCardMedia>
                        <StyledCardContent>
                            <Typography gutterBottom variant="subtitle2">
                                {app.name}
                            </Typography>
                            <Typography variant="caption">{app.description}</Typography>
                            {app?.isLoading && (
                                <StyledPending>
                                    <CircularProgress size={24} color="inherit" />
                                </StyledPending>
                            )}
                        </StyledCardContent>
                        {isNotInOffer && <NotReachableLayer message={i18n.tc('card.notInOffer')} />}
                    </StyledCardActionArea>
                    {!isNotInOffer && isAdmin && (
                        <StyledQuickActions className="quick-actions">
                            <StyledActionInstall tabIndex={0} onClick={handleInstallation}>
                                <InstallIcon width={25} />
                            </StyledActionInstall>
                        </StyledQuickActions>
                    )}
                </StyledCard>
            </Grid>
            {shouldOpenEula && <Eula eula={app.eula} onClose={closeEula} onAccept={acceptEula} />}
        </>
    );
};

NotInstalledApplicationComponent.propTypes = {
    app: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        productsTarget: PropTypes.array.isRequired,
        eula: PropTypes.string,
    }).isRequired,
    isNotInOffer: PropTypes.bool.isRequired,
};

export default NotInstalledApplicationComponent;
