import React from 'react';

import { ErrorPage, SupportContact } from '@braincube/ui';

function GlobalErrorBoundary({ error }) {
    return (
        <ErrorPage title="Oops, something went wrong" details={`${error?.message}&nbsp;${error.stack}`}>
            <SupportContact />
        </ErrorPage>
    );
}

export default GlobalErrorBoundary;
