import { useQuery } from '@tanstack/react-query';
import { getProductInfo } from 'services/api';
import { RQ_KEY_PRODUCT_INFO } from 'services/constants/reactQuery';

function useProductInfo(productUuid) {
    return useQuery({
        queryKey: [RQ_KEY_PRODUCT_INFO, { productUuid }],
        queryFn: () => getProductInfo(productUuid),
        enabled: !!productUuid,
    });
}

export default useProductInfo;
