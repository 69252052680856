import { useI18n } from '@braincube/i18n';
import { Alert, Box, CircularProgress, Stack, TextField, styled } from '@mui/material';
import FitFormContent from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/FitFormContent';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const StyledContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '35px',
    marginBottom: '10px',
    width: '100%',
});

const sx = { mb: 1 };

function PublishStep({ isPublishing }) {
    const i18n = useI18n();
    const {
        handleChange,
        handleBlur,
        values: { name, version },
        errors: { name: nameError, version: versionError },
        touched: { name: nameTouched, version: versionTouched },
    } = useFormikContext();

    return isPublishing ? (
        <StyledContainer>
            <CircularProgress color="inherit" textAlign="center" />
        </StyledContainer>
    ) : (
        <StyledContainer>
            <>
                <Box width="80%" display="flex" flexDirection="column" alignSelf="center" justifyContent="center">
                    <Alert severity="info" sx={sx}>
                        {i18n.tc('digitalAssistantCreator.steps.publish.infoAlert')}
                    </Alert>
                </Box>
                <FitFormContent>
                    <Stack spacing={2}>
                        <TextField
                            data-testid="app-name"
                            required
                            fullWidth
                            name="name"
                            label={i18n.tc('digitalAssistantCreator.fields.name.label')}
                            placeholder={i18n.tc('digitalAssistantCreator.fields.name.placeholder')}
                            value={name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={nameTouched && !!nameError}
                            helperText={nameTouched && nameError}
                        />
                        <TextField
                            data-testid="app-version"
                            required
                            fullWidth
                            name="version"
                            label={i18n.tc('digitalAssistantCreator.fields.version.label')}
                            placeholder="1.0.0"
                            value={version}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={versionTouched && !!versionError}
                            helperText={versionTouched && versionError}
                        />
                    </Stack>
                </FitFormContent>
            </>
        </StyledContainer>
    );
}

PublishStep.propTypes = {
    isPublishing: PropTypes.bool.isRequired,
};

export default PublishStep;
