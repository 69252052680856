export const RQ_KEY_USERS = 'users';
export const RQ_KEY_ACCESS_LIST = 'accessList';
export const RQ_KEY_APP_PERMISSIONS = 'applicationPermissions';
export const RQ_KEY_OFFERS = 'offers';
export const RQ_KEY_APPLICATION_VERSIONS = 'applicationVersions';
export const RQ_KEY_APPLICATIONS = 'applications';
export const RQ_KEY_CUSTOM_APPLICATIONS = 'customApplications';
export const RQ_KEY_PRODUCT_INFO = 'productInfo';
export const RQ_KEY_DA_TEMPLATES = 'digitalAssistantTemplates';
export const RQ_KEY_DA_TEMPLATE = 'digitalAssistantTemplate';
export const RQ_KEY_INSTALLED_DA = 'installedDigitalAssistants';
export const RQ_KEY_DA = 'digitalAssistant';
