export const DA_template_mock = {
    flow: [
        {
            type: 'tab',
            id: '{{{application_uid}}}_0012393ac8c84cc4b612993632200a0b',
            label: 'PiwebApi',
        },
        {
            id: '{{{application_uid}}}_4cd2b40986f84b5db6f09208d3112a95',
            type: 'piwebapi-read',
            z: '{{{application_uid}}}_0012393ac8c84cc4b612993632200a0b',
            name: '',
            mapping: 'Path',
            server: '{{{application_uid}}}_17627b4bd3994e2db7249245803f060e',
            varTable: {
                F1DPe7yWDqLkmk2Y6GJFziKyewcgAAAAREVTS1RPUC1FSk5QNEVNXFRFU1RfVE9UTzEwMA:
                    '\\\\DESKTOP-EJNP4EM\\test_toto100',
                F1DPe7yWDqLkmk2Y6GJFziKyewDwAAAAREVTS1RPUC1FSk5QNEVNXFRFU1RfVE9UTzE: '\\\\DESKTOP-EJNP4EM\\test_toto1',
                F1DPe7yWDqLkmk2Y6GJFziKyewXwAAAAREVTS1RPUC1FSk5QNEVNXFRFU1RfVE9UTzgx:
                    '\\\\DESKTOP-EJNP4EM\\test_toto81',
            },
            extractType: 'live',
            timeWindow: 60000,
            outputSingleMessage: true,
            handleTagRemoval: true,
            emitPastValues: true,
            maxLinePerExec: 500,
            x: 390,
            y: 160,
            wires: [['{{{application_uid}}}_3baadb3e2526404bbcc2bbf55ce47d87']],
        },
        {
            id: '{{{application_uid}}}_17627b4bd3994e2db7249245803f060e',
            type: 'piwebapi-server',
            name: 'Pi Preprod Server',
            url: '{{{17627b4bd3994e2db7249245803f060e/url}}}',
            timeout: '{{{17627b4bd3994e2db7249245803f060e/timeout}}}',
            authType: '{{{17627b4bd3994e2db7249245803f060e/authType}}}',
            kRealm: '',
            kConf: '',
            z: '{{{application_uid}}}_0012393ac8c84cc4b612993632200a0b',
        },
        {
            id: '{{{application_uid}}}_06c6a45903614d0c9eb971a1aeba21da',
            type: 'inject',
            z: '{{{application_uid}}}_0012393ac8c84cc4b612993632200a0b',
            name: '',
            props: [
                {
                    p: 'payload',
                },
                {
                    p: 'topic',
                    vt: 'str',
                },
            ],
            repeat: '',
            crontab: '',
            once: false,
            onceDelay: 0.1,
            topic: '',
            payload: '',
            payloadType: 'date',
            x: 140,
            y: 160,
            wires: [['{{{application_uid}}}_4cd2b40986f84b5db6f09208d3112a95']],
        },
        {
            id: '{{{application_uid}}}_3baadb3e2526404bbcc2bbf55ce47d87',
            type: 'debug',
            z: '{{{application_uid}}}_0012393ac8c84cc4b612993632200a0b',
            name: 'debug 599',
            active: true,
            tosidebar: true,
            console: false,
            tostatus: false,
            complete: 'false',
            statusVal: '',
            statusType: 'auto',
            x: 620,
            y: 160,
            wires: [],
        },
    ],
    name: 'test cricri',
    description: '',
    version: '1.0.0',
    template: [
        {
            id: '17627b4bd3994e2db7249245803f060e',
            type: 'piwebapi-server',
            label: 'Pi Preprod Server',
            properties: [
                {
                    id: '17627b4bd3994e2db7249245803f060e/credentials/user',
                    label: 'credentials.user',
                    inputType: 'string',
                    required: true,
                    default: '',
                },
                {
                    id: '17627b4bd3994e2db7249245803f060e/credentials/password',
                    label: 'credentials.password',
                    inputType: 'string',
                    required: true,
                    default: '',
                },
                {
                    id: '17627b4bd3994e2db7249245803f060e/url',
                    label: 'url',
                    inputType: 'string',
                    default: 'https://10.84.21.201/piwebapi',
                },
                {
                    id: '17627b4bd3994e2db7249245803f060e/timeout',
                    label: 'timeout',
                    inputType: 'string',
                    default: '300',
                },
                {
                    id: '17627b4bd3994e2db7249245803f060e/authType',
                    label: 'authType',
                    inputType: 'string',
                    default: 'basic',
                },
            ],
        },
    ],
    credentials: {
        '{{{application_uid}}}_17627b4bd3994e2db7249245803f060e': {
            user: '{{{17627b4bd3994e2db7249245803f060e/credentials/user}}}',
            password: '{{{17627b4bd3994e2db7249245803f060e/credentials/password}}}',
        },
    },
    dependencies: [
        {
            name: 'node-red',
            version: '5.3.0',
        },
        {
            name: '@braincube/braincubeiot2-contrib-piwebapi',
            version: '1.7.0-master1647',
        },
    ],
    user: 'christophe.colombier@braincube.com',
    createdAt: 1737472464650,
    lastPublishedDate: 1737472464650,
    color: '#34dc42',
    icon: 'AutoAwesomeMotion',
    selectedFlow: {
        id: '0012393ac8c84cc4b612993632200a0b',
        label: 'PiwebApi',
    },
    selectedProperties: [
        '17627b4bd3994e2db7249245803f060e/credentials/user',
        '17627b4bd3994e2db7249245803f060e/credentials/password',
        '17627b4bd3994e2db7249245803f060e/url',
        '17627b4bd3994e2db7249245803f060e/timeout',
        '17627b4bd3994e2db7249245803f060e/authType',
    ],
    selectedStudioApplicationId: 'c8ba68ee-7d9c-4f0f-ad4f-dc62f360ef58',
    studio: {
        apps: {
            name: '[Demo] Braincube as a datasource (MVP2)',
            icon: 'OndemandVideo',
            homePage: '183b9ebc-0816-4dec-99d8-0f828fe7fbcb',
            lastUpdateUser: 'florent.pelenc@braincube.com',
            id: 'c8ba68ee-7d9c-4f0f-ad4f-dc62f360ef58',
            publicationDate: 1729088278500,
            publisher: 'florent.pelenc@braincube.com',
            homepage: '7bfd9c6f-c4c1-4e24-8a5c-55ba3f5a9544',
            dashboardsList: [
                '307f47a3-c33d-4291-9a70-b4af61e903b9',
                '7bfd9c6f-c4c1-4e24-8a5c-55ba3f5a9544',
                'fd079f15-5943-445b-88a0-70bed0eca4d1',
            ],
            formsList: [],
            lastUpdateInformation: {
                wasUpdated: true,
                user: 'florent.pelenc@braincube.com',
                lastVersion: {
                    versionId: 'b04ea493-10f3-44d1-9df0-40051395e1f4',
                    name: 'v2',
                },
            },
            publishedVersion: {
                id: 'b04ea493-10f3-44d1-9df0-40051395e1f4',
                name: 'v2',
            },
            displayNavMenu: false,
            dashboardToPageMapping: {
                '17f6fad5-eb33-4903-89be-f31d776ffad1': '307f47a3-c33d-4291-9a70-b4af61e903b9',
                '97cf6267-b1ae-49fb-9645-cdc1edf7b0d9': '7bfd9c6f-c4c1-4e24-8a5c-55ba3f5a9544',
                'cb99b2a1-1e7f-4da2-a24e-f8033018b7bd': 'fd079f15-5943-445b-88a0-70bed0eca4d1',
            },
        },
        dashboards: [
            {
                parentId: '7bfd9c6f-c4c1-4e24-8a5c-55ba3f5a9544',
                appId: 'c8ba68ee-7d9c-4f0f-ad4f-dc62f360ef58',
                name: 'From scratch',
                elements: [
                    {
                        grid: {
                            w: 2,
                            h: 1,
                            x: 0,
                            y: 0,
                            i: 'f5cb98be-39bb-436a-8ca7-5b8b0490c969',
                            moved: false,
                            static: false,
                        },
                        type: 'Image',
                        props: {
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            payloadKey: '',
                            title: '',
                            description: '',
                            url: 'https://cdn.mybraincube.com/braincube.png',
                            maintainAspectRatio: true,
                        },
                    },
                    {
                        grid: {
                            w: 32,
                            h: 1,
                            x: 2,
                            y: 0,
                            i: '6fb5a729-fec3-4c82-bbdf-0fc77df2c716',
                            moved: false,
                            static: false,
                        },
                        type: 'Text',
                        props: {
                            payloadKey: '',
                            title: '',
                            description: '',
                            value: 'Braincube as a datasource MVP2 - From scratch',
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            backgroundColor: {
                                current: '#000000',
                                enableCustom: true,
                                transparent: false,
                            },
                            defaultFontColor: {
                                current: '#ffdd00',
                                enableCustom: true,
                            },
                            conditionalColors: [],
                        },
                    },
                    {
                        grid: {
                            w: 2,
                            h: 1,
                            x: 34,
                            y: 0,
                            i: 'dece7bea-96da-4a1a-b1fd-aea71923d8d9',
                            moved: false,
                            static: false,
                        },
                        type: 'Image',
                        props: {
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            payloadKey: '',
                            title: '',
                            description: '',
                            url: 'https://cdn-icons-png.flaticon.com/512/776/776339.png',
                            maintainAspectRatio: true,
                            showLinkClick: true,
                            urlLinkClick: 'https://10.84.21.160/edge/#flow/4beb4881.868018',
                        },
                    },
                    {
                        grid: {
                            w: 36,
                            h: 5,
                            x: 0,
                            y: 1,
                            i: '27598ea7-ef43-406a-a8dc-623353f2ff64',
                            moved: false,
                            static: false,
                        },
                        type: 'Bar [NEW]',
                        props: {
                            title: 'Bar [NEW]',
                            description: '',
                            series: [
                                {
                                    uuid: 'c113f6e3-d737-4ae5-8e08-ed9b2a8c1453',
                                    label: 'Example',
                                    data: [],
                                    xPayloadKey: '',
                                    yPayloadKey: '',
                                    numberOfPoints: 10,
                                    isSampleData: true,
                                    enableCustomColor: false,
                                    customColor: '#D0021B',
                                    X: {},
                                    Y: {},
                                },
                            ],
                            nivoData: [],
                            nivoKeys: ['Example'],
                            groupMode: 'stacked',
                            layout: 'vertical',
                            valueScale: {
                                type: 'linear',
                            },
                            reverse: false,
                            minValue: -1000,
                            isMinValueAuto: true,
                            maxValue: 1000,
                            isMaxValueAuto: true,
                            padding: 0.1,
                            innerPadding: 0,
                            colors: {
                                scheme: 'nivo',
                            },
                            borderRadius: 0,
                            enableLabel: true,
                            labelColor: 'inherit',
                            enableGridX: false,
                            enableGridY: true,
                            axisTop: {
                                enable: false,
                                tickRotation: 0,
                                legend: '',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            axisRight: {
                                enable: false,
                                tickRotation: 0,
                                legend: '',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            axisBottom: {
                                enable: true,
                                tickRotation: 0,
                                legend: 'Bottom Axis Legend',
                                enableAutoFormat: false,
                                format: 'time:%H:%M:%S',
                                legendOffset: 0,
                            },
                            axisLeft: {
                                enable: true,
                                tickRotation: 0,
                                legend: 'Left Axis Legend',
                                enableAutoFormat: true,
                                format: 'time:%H:%M:%S',
                                legendOffset: 0,
                            },
                            isInteractive: true,
                            showLegend: true,
                            animate: false,
                            enableAutoFormat: true,
                            format: '>-.2f',
                            enableCanvasRenderMode: false,
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400012: {
                                        label: 'O2 | INPUT10',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'Gris',
                                            enableCustomColor: true,
                                            color: '#9b9b9b',
                                        },
                                    },
                                },
                                dataDepth: '8h',
                                dataRange: 'NB_POINTS',
                                idmxVariableId: 16400033,
                                numberOfPoints: 25,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: false,
                            },
                            enableAutoMargin: true,
                            margin: {
                                top: 20,
                                bottom: 50,
                                left: 50,
                                right: 8,
                            },
                            enableAutoLegendOffset: true,
                        },
                    },
                    {
                        grid: {
                            w: 36,
                            h: 4,
                            x: 0,
                            y: 6,
                            i: '3eeb6876-f8b3-45c5-aa52-1b9e4589f509',
                            moved: false,
                            static: false,
                        },
                        type: 'Text',
                        props: {
                            payloadKey: '',
                            title: 'Text',
                            description: '',
                            value: 42,
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400029: {
                                        label: 'TEMP DRY SLUDGE | INPUT25',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'TEMP DRY SLUDGE | INPUT25',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1h',
                                dataRange: 'PERIOD',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: false,
                            },
                            format: {
                                enableCustomFormat: true,
                                maxNumberDecimal: 2,
                            },
                            fontName: 'Brush Script MT',
                        },
                    },
                    {
                        grid: {
                            w: 36,
                            h: 6,
                            x: 0,
                            y: 10,
                            i: 'faacca31-bf4d-4805-89dc-93b85d783949',
                            moved: false,
                            static: false,
                        },
                        type: 'Gauge',
                        props: {
                            title: 'NOx | OUTPUT03',
                            description: '',
                            min: 0,
                            max: 200,
                            sections: [
                                {
                                    uuid: '6ae6ec68-0ad0-4f94-83a2-bc68b34d67be',
                                    start: 0,
                                    end: 90,
                                    color: '#8b572a',
                                },
                                {
                                    uuid: '97c3b5c2-e511-4504-aca7-b1de72151800',
                                    start: 90,
                                    end: 130,
                                    color: '#F8CC2B',
                                },
                                {
                                    uuid: '370b8f15-fafd-4170-ba8f-c0e163d16ab4',
                                    start: 130,
                                    end: 200,
                                    color: '#EB3536',
                                },
                            ],
                            needles: [
                                {
                                    payloadKey: '',
                                    uuid: 'a10a8f1d-8b19-4d42-8e71-03991cb2f984',
                                    value: 50,
                                    opacity: 1,
                                },
                            ],
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            isBigGauge: true,
                            unit: 'ppm',
                            fontSize: 34,
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400013: {
                                        label: 'NOx | OUTPUT03',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'NOx | OUTPUT03',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1h',
                                dataRange: 'PERIOD',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: true,
                            },
                        },
                    },
                ],
                currentElementUuid: null,
                icon: 'Dashboard',
                background: {
                    type: 'color',
                    enableCustom: false,
                    color: '',
                },
                uuid: '307f47a3-c33d-4291-9a70-b4af61e903b9',
            },
            {
                parentId: null,
                appId: 'c8ba68ee-7d9c-4f0f-ad4f-dc62f360ef58',
                name: 'Homepage',
                elements: [
                    {
                        grid: {
                            w: 2,
                            h: 1,
                            x: 0,
                            y: 0,
                            i: 'cc417883-d4be-4daa-a64e-e760c516e58b',
                            moved: false,
                            static: false,
                        },
                        type: 'Image',
                        props: {
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            payloadKey: '',
                            title: '',
                            description: '',
                            url: 'https://cdn.mybraincube.com/braincube.png',
                            maintainAspectRatio: true,
                        },
                    },
                    {
                        grid: {
                            w: 32,
                            h: 1,
                            x: 2,
                            y: 0,
                            i: '21050311-5c88-4759-94d5-ada77eed8f43',
                            moved: false,
                            static: false,
                        },
                        type: 'Text',
                        props: {
                            payloadKey: '',
                            title: '',
                            description: '',
                            value: 'Braincube as a datasource MVP2 - DEMO',
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            backgroundColor: {
                                current: '#000000',
                                enableCustom: true,
                                transparent: false,
                            },
                            defaultFontColor: {
                                current: '#ffdd00',
                                enableCustom: true,
                            },
                            conditionalColors: [],
                        },
                    },
                    {
                        grid: {
                            w: 2,
                            h: 1,
                            x: 34,
                            y: 0,
                            i: 'f40a67cd-3a45-4cfa-a02e-76a4ec2fa9a5',
                            moved: false,
                            static: false,
                        },
                        type: 'Image',
                        props: {
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            payloadKey: '',
                            title: '',
                            description: '',
                            url: 'https://cdn-icons-png.flaticon.com/512/776/776339.png',
                            maintainAspectRatio: true,
                            showLinkClick: true,
                            urlLinkClick: 'https://10.84.21.160/edge/#flow/81155953.2bd068',
                        },
                    },
                    {
                        grid: {
                            w: 36,
                            h: 5,
                            x: 0,
                            y: 1,
                            i: '61b61c18-60e5-4256-8672-4f47a384a94f',
                            moved: false,
                            static: false,
                        },
                        type: 'Link',
                        props: {
                            title: '',
                            description: '',
                            label: 'From scratch',
                            destination: {
                                type: 'entity',
                                target: '307f47a3-c33d-4291-9a70-b4af61e903b9',
                            },
                            isOpenNewTab: false,
                        },
                    },
                    {
                        grid: {
                            w: 36,
                            h: 6,
                            x: 0,
                            y: 6,
                            i: '9aac7671-0c99-41ce-9a4a-8d62fe85ea9e',
                            moved: false,
                            static: false,
                        },
                        type: 'Link',
                        props: {
                            title: '',
                            description: '',
                            label: 'Example',
                            destination: {
                                type: 'entity',
                                target: 'fd079f15-5943-445b-88a0-70bed0eca4d1',
                            },
                            isOpenNewTab: false,
                        },
                    },
                ],
                icon: 'Dashboard',
                background: {
                    type: 'color',
                    enableCustom: false,
                    color: '',
                },
                uuid: '7bfd9c6f-c4c1-4e24-8a5c-55ba3f5a9544',
            },
            {
                parentId: '7bfd9c6f-c4c1-4e24-8a5c-55ba3f5a9544',
                appId: 'c8ba68ee-7d9c-4f0f-ad4f-dc62f360ef58',
                name: 'Example',
                elements: [
                    {
                        grid: {
                            w: 36,
                            h: 4,
                            x: 0,
                            y: 3,
                            i: 'bf9a47b3-006f-44b6-b9de-a4fa09250b46',
                            moved: false,
                            static: false,
                        },
                        type: 'Line [NEW]',
                        props: {
                            title: 'FLOW CONTINUOUS BLOWDOWN | INPUT07',
                            description: 'Récupération des valeurs sur les 3 derniers jours',
                            series: [
                                {
                                    uuid: '2e7b688c-23d2-42e5-8669-01f5ed76fd49',
                                    label: 'Example',
                                    data: [],
                                    xPayloadKey: '',
                                    yPayloadKey: '',
                                    numberOfPoints: 10,
                                    isSampleData: true,
                                    enableCustomColor: false,
                                    customColor: '#D0021B',
                                    X: {},
                                    Y: {},
                                },
                            ],
                            xScale: {
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                            },
                            yScale: {
                                type: 'linear',
                            },
                            showLegend: false,
                            enablePoints: true,
                            pointSize: 10,
                            enablePointLabel: false,
                            pointLabel: 'y',
                            pointLabelY: -12,
                            curve: 'linear',
                            colors: {
                                scheme: 'nivo',
                            },
                            lineWidth: 2,
                            enableArea: false,
                            areaOpacity: 0.2,
                            enableGridX: true,
                            enableGridY: true,
                            interactivity: 'onePoint',
                            areaBlendMode: 'normal',
                            axisTop: {
                                enable: false,
                                tickRotation: 0,
                                legend: '',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            axisBottom: {
                                enable: true,
                                tickRotation: 0,
                                legend: 'Time',
                                enableAutoFormat: false,
                                format: 'time:%Y-%m-%d %H:%M:%S',
                                legendOffset: 0,
                            },
                            axisLeft: {
                                enable: true,
                                tickRotation: 0,
                                legend: 'Value',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            axisRight: {
                                enable: false,
                                tickRotation: 0,
                                legend: '',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            enableAutoMinValue: true,
                            enableAutoMaxValue: true,
                            minValue: -1000,
                            maxValue: 1000,
                            enableXAutoFormat: true,
                            enableYAutoFormat: true,
                            xFormat: '>-',
                            yFormat: '>-.2f',
                            animate: false,
                            enableCanvasRenderMode: false,
                            pointStyle: 'outlined',
                            pointBorderWidth: 2,
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400009: {
                                        label: 'FLOW CONTINUOUS BLOWDOWN | INPUT07',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'FLOW CONTINUOUS BLOWDOWN | INPUT07',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '3d',
                                dataRange: 'PERIOD',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: false,
                            },
                            enableAutoMargin: true,
                            margin: {
                                top: 8,
                                bottom: 50,
                                left: 50,
                                right: 8,
                            },
                            enableAutoLegendOffset: true,
                            showLinkClick: true,
                            urlLinkClick: 'myLink',
                            isOpenNewTab: 'NEW_TAB',
                        },
                    },
                    {
                        grid: {
                            w: 36,
                            h: 4,
                            x: 0,
                            y: 7,
                            i: '986828c2-0dc0-4497-9352-848a93bcf900',
                            moved: false,
                            static: false,
                        },
                        type: 'Line [NEW]',
                        props: {
                            title: 'FLOW CONTINUOUS BLOWDOWN | INPUT07',
                            description: 'Récupération des 50 dernières valeurs',
                            series: [
                                {
                                    uuid: '6e38e0f3-9497-449c-bb14-542de00afebf',
                                    label: 'Example',
                                    data: [],
                                    xPayloadKey: '',
                                    yPayloadKey: '',
                                    numberOfPoints: 10,
                                    isSampleData: true,
                                    enableCustomColor: false,
                                    customColor: '#D0021B',
                                    X: {},
                                    Y: {},
                                },
                            ],
                            xScale: {
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                            },
                            yScale: {
                                type: 'linear',
                            },
                            showLegend: false,
                            enablePoints: true,
                            pointSize: 10,
                            enablePointLabel: false,
                            pointLabel: 'y',
                            pointLabelY: -12,
                            curve: 'linear',
                            colors: {
                                scheme: 'nivo',
                            },
                            lineWidth: 2,
                            enableArea: false,
                            areaOpacity: 0.2,
                            enableGridX: true,
                            enableGridY: true,
                            interactivity: 'onePoint',
                            areaBlendMode: 'normal',
                            axisTop: {
                                enable: false,
                                tickRotation: 0,
                                legend: '',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            axisBottom: {
                                enable: true,
                                tickRotation: 0,
                                legend: 'Time',
                                enableAutoFormat: false,
                                format: 'time:%Y-%m-%d %H:%M:%S',
                                legendOffset: 0,
                            },
                            axisLeft: {
                                enable: true,
                                tickRotation: 0,
                                legend: 'Value',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            axisRight: {
                                enable: false,
                                tickRotation: 0,
                                legend: '',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            enableAutoMinValue: true,
                            enableAutoMaxValue: true,
                            minValue: -1000,
                            maxValue: 1000,
                            enableXAutoFormat: true,
                            enableYAutoFormat: true,
                            xFormat: '>-',
                            yFormat: '>-.2f',
                            animate: false,
                            enableCanvasRenderMode: false,
                            pointStyle: 'outlined',
                            pointBorderWidth: 2,
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400009: {
                                        label: 'FLOW CONTINUOUS BLOWDOWN | INPUT07',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'FLOW CONTINUOUS BLOWDOWN | INPUT07',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1d',
                                dataRange: 'NB_POINTS',
                                idmxVariableId: 16400033,
                                numberOfPoints: 50,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: false,
                            },
                            enableAutoMargin: true,
                            margin: {
                                top: 8,
                                bottom: 50,
                                left: 50,
                                right: 8,
                            },
                            enableAutoLegendOffset: true,
                        },
                    },
                    {
                        grid: {
                            w: 2,
                            h: 1,
                            x: 0,
                            y: 0,
                            i: 'b2753535-21a5-4643-bfe3-63aa8f6c1421',
                            moved: false,
                            static: false,
                        },
                        type: 'Image',
                        props: {
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            payloadKey: '',
                            title: '',
                            description: '',
                            url: 'https://cdn.mybraincube.com/braincube.png',
                            maintainAspectRatio: true,
                        },
                    },
                    {
                        grid: {
                            w: 32,
                            h: 1,
                            x: 2,
                            y: 0,
                            i: 'cae429cd-7fe3-492c-8a1e-841a7326a6a3',
                            moved: false,
                            static: false,
                        },
                        type: 'Text',
                        props: {
                            payloadKey: '',
                            title: '',
                            description: '',
                            value: 'Braincube as a datasource MVP2 - Example',
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            backgroundColor: {
                                current: '#000000',
                                enableCustom: true,
                                transparent: false,
                            },
                            defaultFontColor: {
                                current: '#ffdd00',
                                enableCustom: true,
                            },
                            conditionalColors: [],
                        },
                    },
                    {
                        grid: {
                            w: 2,
                            h: 1,
                            x: 34,
                            y: 0,
                            i: '67c8d398-cd52-4586-aa18-a79c71059163',
                            moved: false,
                            static: false,
                        },
                        type: 'Image',
                        props: {
                            dataSource: {
                                type: 'MANUAL',
                                value: '',
                                payloadKeys: [],
                            },
                            payloadKey: '',
                            title: '',
                            description: '',
                            url: 'https://cdn-icons-png.flaticon.com/512/776/776339.png',
                            maintainAspectRatio: true,
                            showLinkClick: true,
                            urlLinkClick: 'https://10.84.21.160/edge/#flow/4beb4881.868018',
                        },
                    },
                    {
                        grid: {
                            w: 9,
                            h: 5,
                            x: 0,
                            y: 15,
                            i: 'bf849c71-6cb3-432b-88d9-0f5efb5d7413',
                            moved: false,
                            static: false,
                        },
                        type: 'Gauge',
                        props: {
                            title: 'FUEL FEEDING SCREW 1 | INPUT01',
                            description: '',
                            min: 0,
                            max: 98,
                            sections: [
                                {
                                    uuid: 'c5994500-f234-41da-9d88-2a10c9db010e',
                                    start: 0,
                                    end: 20,
                                    color: '#d0021b',
                                },
                                {
                                    uuid: 'fa973336-909e-47f9-b68c-4dc54ca13ec9',
                                    start: 20,
                                    end: 30,
                                    color: '#f8e71c',
                                },
                                {
                                    uuid: '139c8814-e3d4-4c30-8be1-c08c6af04ad0',
                                    start: 30,
                                    end: 70,
                                    color: '#7ed321',
                                },
                                {
                                    uuid: '6df6a24c-0e61-49e6-9643-1034e5bafaa5',
                                    start: 70,
                                    end: 80,
                                    color: '#f8e71c',
                                },
                                {
                                    uuid: '88106d16-d054-45ab-bc89-015f4ab99556',
                                    start: 80,
                                    end: 100,
                                    color: '#d0021b',
                                },
                            ],
                            needles: [
                                {
                                    payloadKey: '',
                                    uuid: '5ee3884d-9d39-4f36-8002-e95dd1d9f831',
                                    value: 50,
                                    opacity: 1,
                                },
                            ],
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            isBigGauge: true,
                            unit: '',
                            fontSize: 34,
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400003: {
                                        label: 'FUEL FEEDING SCREW 1 | INPUT01',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'FUEL FEEDING SCREW 1 | INPUT01',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1h',
                                dataRange: 'PERIOD',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: true,
                            },
                            isShowLimitSection: true,
                        },
                    },
                    {
                        grid: {
                            w: 9,
                            h: 5,
                            x: 9,
                            y: 15,
                            i: '8c30c375-853b-4a4c-af14-032b9298f515',
                            moved: false,
                            static: false,
                        },
                        type: 'Gauge',
                        props: {
                            title: 'PURGE AIR COMPRESSOR | INPUT02',
                            description: '',
                            min: 0,
                            max: 200,
                            sections: [
                                {
                                    uuid: 'a9cc1f9d-40ae-4f2b-8de9-c9173de2f2e7',
                                    start: 0,
                                    end: 90,
                                    color: '#03BB07',
                                },
                                {
                                    uuid: 'fce01588-b4bc-4ba0-a75e-e6ba1ac2bfe1',
                                    start: 90,
                                    end: 130,
                                    color: '#F8CC2B',
                                },
                                {
                                    uuid: '11760e65-15b6-4bbb-ab03-236320b4125a',
                                    start: 130,
                                    end: 200,
                                    color: '#EB3536',
                                },
                            ],
                            needles: [
                                {
                                    payloadKey: '',
                                    uuid: '4844db1e-0829-4a6b-b779-e7ba4fdef1b7',
                                    value: 50,
                                    opacity: 1,
                                },
                            ],
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            isBigGauge: true,
                            unit: '',
                            fontSize: 34,
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400004: {
                                        label: 'PURGE AIR COMPRESSOR | INPUT02',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'PURGE AIR COMPRESSOR | INPUT02',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1h',
                                dataRange: 'PERIOD',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: true,
                            },
                            isShowLimitSection: true,
                        },
                    },
                    {
                        grid: {
                            w: 9,
                            h: 5,
                            x: 18,
                            y: 15,
                            i: 'b66d1462-f7f2-49df-a320-b1c3faa9f1d6',
                            moved: false,
                            static: false,
                        },
                        type: 'Gauge',
                        props: {
                            title: 'FLOW OVERHEATED STEAM | INPUT03',
                            description: '',
                            min: 0,
                            max: 200,
                            sections: [
                                {
                                    uuid: 'a2252ea6-9872-41a4-8953-dd26c101eaae',
                                    start: 0,
                                    end: 90,
                                    color: '#03BB07',
                                },
                                {
                                    uuid: 'c8e545d3-db15-413d-9d93-f738bdfb62c5',
                                    start: 90,
                                    end: 130,
                                    color: '#F8CC2B',
                                },
                                {
                                    uuid: '8a9c862a-5904-4053-8c78-d5eec2ccc9f5',
                                    start: 130,
                                    end: 200,
                                    color: '#EB3536',
                                },
                            ],
                            needles: [
                                {
                                    payloadKey: '',
                                    uuid: '58418f06-1304-414a-b00a-4917a340b24f',
                                    value: 50,
                                    opacity: 1,
                                },
                            ],
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            isBigGauge: true,
                            unit: '',
                            fontSize: 34,
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400005: {
                                        label: 'FLOW OVERHEATED STEAM | INPUT03',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'FLOW OVERHEATED STEAM | INPUT03',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1h',
                                dataRange: 'PERIOD',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: true,
                            },
                            isShowLimitSection: true,
                        },
                    },
                    {
                        grid: {
                            w: 9,
                            h: 5,
                            x: 27,
                            y: 15,
                            i: 'adc7845d-3306-442a-af81-c35c30c8db9c',
                            moved: false,
                            static: false,
                        },
                        type: 'Gauge',
                        props: {
                            title: 'FLOW SECONDARY AIR | INPUT04',
                            description: '',
                            min: 0,
                            max: 200,
                            sections: [
                                {
                                    uuid: '8554f86c-1429-4aaf-892b-4400ee6bf712',
                                    start: 0,
                                    end: 90,
                                    color: '#03BB07',
                                },
                                {
                                    uuid: '3d919e78-2454-44fa-998a-83bdddd8a8a5',
                                    start: 90,
                                    end: 130,
                                    color: '#F8CC2B',
                                },
                                {
                                    uuid: 'a3d18d2a-5481-4fa5-b819-6c62cbac715f',
                                    start: 130,
                                    end: 200,
                                    color: '#EB3536',
                                },
                            ],
                            needles: [
                                {
                                    payloadKey: '',
                                    uuid: '0b62e2ab-8c11-44fd-9d9a-5c8b2105e86a',
                                    value: 50,
                                    opacity: 1,
                                },
                            ],
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            isBigGauge: true,
                            unit: '',
                            fontSize: 34,
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400006: {
                                        label: 'FLOW SECONDARY AIR | INPUT04',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'FLOW SECONDARY AIR | INPUT04',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1h',
                                dataRange: 'PERIOD',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: true,
                            },
                            isShowLimitSection: true,
                        },
                    },
                    {
                        grid: {
                            w: 36,
                            h: 2,
                            x: 0,
                            y: 1,
                            i: '02efc6bc-7e58-44c8-9a9e-3fd14b330728',
                            moved: false,
                            static: false,
                        },
                        type: 'Text',
                        props: {
                            payloadKey: '',
                            title: 'BOILER OPERATIONAL',
                            description: '',
                            value: 42,
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            areParametersDynamic: false,
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400048: {
                                        label: 'BOILER OPERATIONAL (discrete : on / off)',
                                        type: 'DISCRETE',
                                        customSeriesProps: {
                                            label: 'BOILER OPERATIONAL (discrete : on / off)',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1h',
                                dataRange: 'PERIOD',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: false,
                            },
                        },
                    },
                    {
                        grid: {
                            w: 36,
                            h: 4,
                            x: 0,
                            y: 11,
                            i: '8cb6e905-62f3-4075-9932-ceea9baa4bde',
                            moved: false,
                            static: false,
                        },
                        type: 'Bar [NEW]',
                        props: {
                            title: 'Bar [NEW]',
                            description: '',
                            series: [
                                {
                                    uuid: 'b05ddcbf-4c58-4317-9ea9-5ba58d2a01ca',
                                    label: 'Example',
                                    data: [],
                                    xPayloadKey: '',
                                    yPayloadKey: '',
                                    numberOfPoints: 10,
                                    isSampleData: true,
                                    enableCustomColor: false,
                                    customColor: '#D0021B',
                                    X: {},
                                    Y: {},
                                },
                            ],
                            nivoData: [],
                            nivoKeys: ['Example'],
                            groupMode: 'stacked',
                            layout: 'vertical',
                            valueScale: {
                                type: 'linear',
                            },
                            reverse: false,
                            minValue: -1000,
                            isMinValueAuto: true,
                            maxValue: 1000,
                            isMaxValueAuto: true,
                            padding: 0.1,
                            innerPadding: 0,
                            colors: {
                                scheme: 'nivo',
                            },
                            borderRadius: 0,
                            enableLabel: true,
                            labelColor: 'inherit',
                            enableGridX: false,
                            enableGridY: true,
                            axisTop: {
                                enable: false,
                                tickRotation: 0,
                                legend: '',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            axisRight: {
                                enable: false,
                                tickRotation: 0,
                                legend: '',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            axisBottom: {
                                enable: true,
                                tickRotation: 0,
                                legend: 'Time',
                                enableAutoFormat: false,
                                format: 'time:%H:%M:%S',
                                legendOffset: 0,
                            },
                            axisLeft: {
                                enable: true,
                                tickRotation: 0,
                                legend: 'NOx (ppm)',
                                enableAutoFormat: true,
                                format: 'time:%Y-%m-%d',
                                legendOffset: 0,
                            },
                            isInteractive: true,
                            showLegend: true,
                            animate: false,
                            enableAutoFormat: true,
                            format: '>-.2f',
                            enableCanvasRenderMode: false,
                            dataSource: {
                                type: 'MB/DT',
                                value: 'f466b2d2.d2f0c',
                                payloadKeys: [],
                            },
                            braincubeConfiguration: {
                                mbId: 164,
                                mbName: 'Demo Live Boiler House',
                                referenceDateId: 16400001,
                                variables: {
                                    16400013: {
                                        label: 'NOx | OUTPUT03',
                                        type: 'NUMERICAL',
                                        customSeriesProps: {
                                            label: 'NOx | OUTPUT03',
                                            enableCustomColor: false,
                                            color: '#D0021B',
                                        },
                                    },
                                },
                                dataDepth: '1h',
                                dataRange: 'NB_POINTS',
                                idmxVariableId: 16400033,
                                numberOfPoints: 10,
                                needleColor: '#000000',
                                needleOpacity: 1,
                                needleIsSameColorThanSection: false,
                            },
                            enableAutoMargin: true,
                            margin: {
                                top: 20,
                                bottom: 50,
                                left: 50,
                                right: 8,
                            },
                            enableAutoLegendOffset: true,
                        },
                    },
                ],
                currentElementUuid: null,
                icon: 'Dashboard',
                background: {
                    type: 'color',
                    enableCustom: false,
                    color: '',
                },
                uuid: 'fd079f15-5943-445b-88a0-70bed0eca4d1',
            },
        ],
        forms: [],
        versions: [
            {
                uuid: 'b04ea493-10f3-44d1-9df0-40051395e1f4',
                name: 'v2',
                description: 'Potentiellement daubée',
                publisher: 'florent.pelenc@braincube.com',
                publicationDate: 1729088278500,
                flowChange: false,
            },
            {
                uuid: '123e035c-1abb-464f-99c9-19f2ce146947',
                name: 'v1',
                description: '',
                publisher: 'florent.pelenc@braincube.com',
                publicationDate: 1724663688470,
                flowChange: false,
            },
        ],
    },
};
