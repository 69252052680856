import { useMemo } from 'react';

import useOffers from 'services/hooks/react-query/useOffers';
import useProductInfo from 'services/hooks/react-query/useProductInfo';
import { useHeader } from 'services/hooks/useHeader';

export default function useProductOffers() {
    const { selectedProductUuid } = useHeader();
    const { data: offers } = useOffers(selectedProductUuid);
    const { data: productInfo } = useProductInfo(selectedProductUuid);

    return useMemo(() => offers?.filter((offer) => productInfo.offers.includes(offer.id)), [offers, productInfo]);
}
