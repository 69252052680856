import { useMemo } from 'react';

import { useHeader } from 'services/hooks/useHeader';

export default function useIsAdmin() {
    const { accessList, braincubeProduct, user } = useHeader();

    return useMemo(
        () =>
            user?.rootAdmin ||
            ['IPLWADMIN', 'COACH', 'ADMIN'].includes(
                accessList.find(({ product }) => {
                    return product.productId === braincubeProduct?.productId;
                })?.accessRight,
            ),
        [accessList, braincubeProduct, user],
    );
}
