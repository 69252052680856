import { TextField } from '@mui/material';
import fieldPropType from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep/services/constants';
import { useFormikContext } from 'formik';
import React from 'react';

function NumberField({ property }) {
    const { values, errors, handleChange } = useFormikContext();

    /**
     * Note: although type "number" is not recommended by @mui/material as it allow certain
     * non-numeric characters ('e','+','-','.'), in this case it does not matter as editable properties
     * of type "number" coming from NR flows allows as well those non-numeric characters.
     * see https://mui.com/material-ui/react-text-field/#type-quot-number-quot
     */
    return (
        <TextField
            fullWidth
            type="number"
            name={`['${property.id}']`}
            label={property.label}
            placeholder={property.default.toString()}
            required
            value={values[property.id]}
            onChange={handleChange}
            error={!!errors[property.id]}
            helperText={errors[property.id]}
        />
    );
}

NumberField.propTypes = fieldPropType;

export default NumberField;
