import { useQuery } from '@tanstack/react-query';
import { getApplications } from 'services/api';
import { RQ_KEY_APPLICATIONS } from 'services/constants/reactQuery';

function useApplications(productUuid) {
    return useQuery({
        queryKey: [RQ_KEY_APPLICATIONS, { productUuid }],
        queryFn: () => getApplications(productUuid),
        enabled: !!productUuid,
    });
}

export default useApplications;
