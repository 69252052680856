import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProductApplication } from 'services/api';
import { RQ_KEY_APPLICATIONS, RQ_KEY_PRODUCT_INFO } from 'services/constants/reactQuery';

function generateBody({ toInstallUuid, toDeleteUuid, forceRemove, toUpdateUuid, versionUuid }) {
    const body = [];

    if (toInstallUuid) {
        body.push({
            appId: toInstallUuid,
            action: 'INSTALL',
        });
    }

    if (toDeleteUuid) {
        body.push({
            appId: toDeleteUuid,
            action: forceRemove ? 'FORCE_DELETE' : 'DELETE',
        });
    }

    if (toUpdateUuid) {
        if (Array.isArray(toUpdateUuid)) {
            for (const uuid of toUpdateUuid) {
                body.push({
                    appId: uuid,
                    action: 'UPDATE',
                });
            }
        } else {
            body.push({
                appId: toUpdateUuid,
                action: 'UPDATE',
            });
        }
    }

    // To change an application version, the appId must NOT be forwarded, but the appVersionId instead
    if (versionUuid) {
        body.push({
            action: 'UPDATE',
            appVersionId: versionUuid,
        });
    }

    return body;
}

function useApplicationMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({
            productUuid,
            toInstallUuid,
            toDeleteUuid,
            forceRemove = false,
            toUpdateUuid,
            versionUuid,
            stompClient,
        }) => {
            const body = generateBody({ toInstallUuid, toDeleteUuid, forceRemove, toUpdateUuid, versionUuid });

            return updateProductApplication(productUuid, body);
        },
        onMutate: async ({ productUuid, stompClient, toInstallUuid, toDeleteUuid, toUpdateUuid, versionUuid }) => {
            // Cancel any outgoing refetches for the applications query to avoid race conditions
            await queryClient.cancelQueries({ queryKey: [RQ_KEY_APPLICATIONS, { productUuid }] });

            // Determine the appId based on the mutation parameters
            const appId = toInstallUuid || toDeleteUuid || toUpdateUuid || versionUuid;

            // Get the current cache for the applications query
            const previousCache = queryClient.getQueryData([RQ_KEY_APPLICATIONS, { productUuid }]);

            // Optimistically update the cache to reflect the loading state of the application being mutated
            queryClient.setQueryData([RQ_KEY_APPLICATIONS, { productUuid }], (oldData) => {
                if (oldData) {
                    // Remove the current application from the old data
                    const newData = oldData.filter((data) => data.id !== appId);

                    // Find the current application and set its loading state to true
                    let monApp = oldData.find((data) => data.id === appId);
                    monApp = {
                        ...monApp,
                        isLoading: true,
                    };

                    // Add the updated application back to the data
                    newData.push(monApp);

                    return newData;
                }
            });

            // Subscribe to the WebSocket topic for the product to receive updates
            stompClient?.subscribe(`/topic/${productUuid}`, (message) => {
                // Invalidate the queries for product info and applications to refetch the latest data
                if (message) {
                    queryClient.invalidateQueries({ queryKey: [RQ_KEY_PRODUCT_INFO, { productUuid }] });
                    queryClient.invalidateQueries({ queryKey: [RQ_KEY_APPLICATIONS, { productUuid }] });
                }
            });

            return { previousCache };
        },
    });
}

export default useApplicationMutation;
