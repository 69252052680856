import { useQuery } from '@tanstack/react-query';

import { getDigitalAssistantTemplates } from 'services/api';
import { DA_CATEGORY } from 'services/constants/categories';
import { RQ_KEY_DA_TEMPLATES } from 'services/constants/reactQuery';
import useFeatureAccess from 'services/hooks/useFeatureAccess';

function useDigitalAssistantTemplates(productUuid) {
    const { hasAccess: hasAccessToDigitalAssistant = false } = useFeatureAccess('appStore.digitalAssistantAvailable');

    return useQuery({
        queryKey: [RQ_KEY_DA_TEMPLATES, productUuid],
        queryFn: () => getDigitalAssistantTemplates(productUuid),
        select: (data) =>
            data.map((digitalAssistantTemplate) => ({ ...digitalAssistantTemplate, category: DA_CATEGORY })),
        enabled: hasAccessToDigitalAssistant && !!productUuid,
        // TODO: Add retry logic when the endpoint is ready in preproduction
        retry: 0,
    });
}

export default useDigitalAssistantTemplates;
