import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import fieldPropType from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep/services/constants';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

const RadioComponent = <Radio />;

function BooleanField({ property }) {
    const { values, setFieldValue } = useFormikContext();

    const onChange = useCallback(
        (event, value) => {
            setFieldValue(`['${property.id}']`, value === true || value === 'true');
        },
        [property.id, setFieldValue],
    );

    return (
        <FormControl>
            <FormLabel>{property.label}</FormLabel>
            <RadioGroup row name={`['${property.id}']`} value={values[property.id]} onChange={onChange}>
                <FormControlLabel value control={RadioComponent} label="true" />
                <FormControlLabel value={false} control={RadioComponent} label="false" />
            </RadioGroup>
        </FormControl>
    );
}

BooleanField.propTypes = fieldPropType;

export default BooleanField;
