import { useI18n } from '@braincube/i18n';
import {
    Button,
    Card,
    CardActionArea,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { EditShareMode } from '@braincube/svg';
import {
    DeleteOutline as Delete,
    Download,
    Error as ErrorIcon,
    InfoOutlined as Info,
    Update,
} from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SystemUpdateAlt from '@mui/icons-material/SystemUpdateAlt';

import ChangeVersionDialog from 'components/Content/components/InstalledApplications/components/Application/components/ChangeVersionDialog';
import ShareDialog from 'components/Content/components/InstalledApplications/components/Application/components/ShareDialog';
import NotReachableLayer from 'components/NotReachableLayer';
import { useSelectedApp } from 'services/context/SelectedAppProvider';
import { useWebSocket } from 'services/context/WebSocket';
import { getAppIcon } from 'services/helpers';
import useApplicationMutation from 'services/hooks/react-query/useApplicationMutation';
import useGetDeprecatedInstalledApplications from 'services/hooks/useGetDeprecatedInstalledApplications';
import { useHeader } from 'services/hooks/useHeader';
import useIsAdmin from 'services/hooks/useIsAdmin';
import useIsSelectedBoxIoT from 'services/hooks/useIsSelectedBoxIoT';

const StyledAction = styled('div')(({ theme }) => ({
    width: 35,
    height: 35,
    marginBottom: 1,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledEditShareMode = styled(EditShareMode)({
    '& > .st0': {
        fill: 'none',
    },
});

const StyledPending = styled('div')(({ theme }) => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
}));

const StyledDeprecated = styled('div')(({ theme }) => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    color: '#F00',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
}));

const StyledQuickActions = styled('div')({
    display: 'block',
    top: 0,
    right: 0,
    position: 'absolute',
    width: 35,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    opacity: 0,
    transition: 'ease-in 0.2s',
});

const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'backgroundColor' })(({ backgroundColor }) => ({
    backgroundColor,
    position: 'relative',
    '&:hover': {
        '& .quick-actions': {
            opacity: 1,
        },
    },
    height: 150,
}));

const StyledCardActionArea = styled(CardActionArea)({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    '&:hover': {
        '& .quick-actions': {
            opacity: 1,
        },
    },
});

const StyledCardAppIcon = styled('div')({
    display: 'flex',
    height: '60%',
    alignItems: 'center',
    fontSize: 60,
});

const StyledCardAppName = styled('div', { shouldForwardProp: (prop) => prop !== 'color' })(({ color }) => ({
    color,
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    height: '40%',
    alignItems: 'center',
}));

export const APP_STATE = {
    PENDING_INSTALL: 'PENDING_INSTALL',
    PENDING_UNINSTALL: 'PENDING_UNINSTALL',
    PENDING_UPDATE: 'PENDING_UPDATE',
    ERROR_INSTALL: 'ERROR_INSTALL',
    ERROR_UNINSTALL: 'ERROR_UNINSTALL',
    ERROR_UPDATE: 'ERROR_UPDATE',
    INSTALLED: 'INSTALLED',
};

export const Application = ({ app, state }) => {
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [changeVersionDialogOpen, setChangeVersionDialogOpen] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const theme = useTheme();
    const i18n = useI18n();
    const isAdmin = useIsAdmin();
    const { selectedProductUuid } = useHeader();

    const deprecatedInstalledApplications = useGetDeprecatedInstalledApplications();
    const { mutate: updateApplication } = useApplicationMutation();
    const { setSelectedApp } = useSelectedApp();
    const isSelectedBoxIoT = useIsSelectedBoxIoT();
    const { stompClient } = useWebSocket();

    const handleDialogOpen = useCallback((setDialogOpen) => {
        setDialogOpen(true);
    }, []);

    const handleDialogClose = useCallback((setDialogOpen) => {
        setDialogOpen(false);
    }, []);

    const openDialog = useCallback((e, handleClickDialogOpen) => {
        e.stopPropagation();
        e.preventDefault();
        handleClickDialogOpen();
    }, []);

    // ShareDialog
    const handleShareDialogOpen = useCallback(() => handleDialogOpen(setShareDialogOpen), [handleDialogOpen]);
    const handleShareDialogClose = useCallback(() => handleDialogClose(setShareDialogOpen), [handleDialogClose]);
    const openShareDialog = useCallback(
        (e) => openDialog(e, handleShareDialogOpen),
        [handleShareDialogOpen, openDialog],
    );

    // ChangeVersionDialog
    const handleChangeVersionDialogOpen = useCallback(
        () => handleDialogOpen(setChangeVersionDialogOpen),
        [handleDialogOpen],
    );
    const handleChangeVersionDialogClose = useCallback(
        (versionUuid) => {
            if (versionUuid) {
                updateApplication({
                    productUuid: selectedProductUuid,
                    versionUuid: versionUuid,
                    stompClient,
                });
            }
            handleDialogClose(setChangeVersionDialogOpen);
        },
        [handleDialogClose, selectedProductUuid, stompClient, updateApplication],
    );
    const openChangeVersionDialog = useCallback(
        (e) => openDialog(e, handleChangeVersionDialogOpen),
        [handleChangeVersionDialogOpen, openDialog],
    );

    const triggerDelete = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setConfirmDeletion(true);
    }, []);

    const triggerUpdateApplication = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            updateApplication({
                productUuid: selectedProductUuid,
                toUpdateUuid: app.id,
                stompClient,
            });
        },
        [app.id, selectedProductUuid, updateApplication, stompClient],
    );

    const showInfos = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedApp(app);
        },
        [app, setSelectedApp],
    );

    const confirmDelete = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setConfirmDeletion(true);
    }, []);

    const renderQuickAction = useCallback(
        (error = false) => {
            return (
                <StyledQuickActions className="quick-actions" data-testid="app-card-quick-actions">
                    {!error && isAdmin && (
                        <>
                            <Tooltip title={i18n.tc('card.tooltip.delete')} disableInteractive>
                                <StyledAction tabIndex={0} onClick={triggerDelete}>
                                    <Delete htmlColor="#FFF" />
                                </StyledAction>
                            </Tooltip>
                            {!isSelectedBoxIoT && (
                                <Tooltip title={i18n.tc('card.tooltip.permissions')} disableInteractive>
                                    <StyledAction tabIndex={0} onClick={openShareDialog}>
                                        <StyledEditShareMode htmlColor="#FFF" />
                                    </StyledAction>
                                </Tooltip>
                            )}
                            {deprecatedInstalledApplications.some((application) => application.appId === app.id) && (
                                <Tooltip title={i18n.tc('card.tooltip.update')} disableInteractive>
                                    <StyledAction tabIndex={0} onClick={triggerUpdateApplication}>
                                        <Update htmlColor="#FFF" />
                                    </StyledAction>
                                </Tooltip>
                            )}
                            {app.productsTarget.includes('IOT') && (
                                <Tooltip title={i18n.tc('card.tooltip.version')} disableInteractive>
                                    <StyledAction
                                        tabIndex={0}
                                        onClick={openChangeVersionDialog}
                                        data-testid="quick-action-change-version-action"
                                    >
                                        <Download htmlColor="#FFF" />
                                    </StyledAction>
                                </Tooltip>
                            )}
                        </>
                    )}
                    {!error && (
                        <Tooltip title={i18n.tc('card.tooltip.info')}>
                            <StyledAction tabIndex={0} onClick={showInfos}>
                                <Info htmlColor="#FFF" />
                            </StyledAction>
                        </Tooltip>
                    )}
                    {error && (
                        <Tooltip title={i18n.tc('card.tooltip.delete')}>
                            <StyledAction tabIndex={0} onClick={confirmDelete}>
                                <DeleteForeverIcon htmlColor="#FFF" />
                            </StyledAction>
                        </Tooltip>
                    )}
                </StyledQuickActions>
            );
        },
        [
            isAdmin,
            isSelectedBoxIoT,
            i18n,
            app,
            deprecatedInstalledApplications,
            openChangeVersionDialog,
            openShareDialog,
            showInfos,
            triggerDelete,
            triggerUpdateApplication,
            confirmDelete,
        ],
    );

    const layer = useMemo(() => {
        if (app?.isLoading) {
            return (
                <StyledPending>
                    <CircularProgress size={24} color="inherit" />
                </StyledPending>
            );
        }

        switch (state) {
            case APP_STATE.PENDING_INSTALL:
            case APP_STATE.PENDING_UNINSTALL:
            case APP_STATE.PENDING_UPDATE:
                return (
                    <StyledPending>
                        <CircularProgress size={24} color="inherit" />
                    </StyledPending>
                );

            case APP_STATE.ERROR_INSTALL:
            case APP_STATE.ERROR_UNINSTALL:
            case APP_STATE.ERROR_UPDATE:
                return (
                    <>
                        <StyledPending>
                            <ErrorIcon color="inherit" />
                        </StyledPending>
                        {renderQuickAction(true)}
                    </>
                );
            default:
                if (deprecatedInstalledApplications.some((application) => application.appId === app.id)) {
                    return (
                        <>
                            <StyledDeprecated>
                                <SystemUpdateAlt color="inherit" />
                            </StyledDeprecated>
                            {renderQuickAction(false)}
                        </>
                    );
                }

                return (
                    <>
                        {app.reachable && renderQuickAction()}
                        {!app.reachable && <NotReachableLayer message={i18n.tc('card.disabled')} />}
                    </>
                );
        }
    }, [app, deprecatedInstalledApplications, i18n, state, renderQuickAction]);

    const closeConfirmDelete = useCallback(() => setConfirmDeletion(false), []);

    const triggerRemoveApplication = useCallback(() => {
        updateApplication({ productUuid: selectedProductUuid, toDeleteUuid: app.id, stompClient });
        closeConfirmDelete();
    }, [app.id, closeConfirmDelete, updateApplication, selectedProductUuid, stompClient]);

    return (
        <Grid item xs={6} sm={4} lg={2}>
            <StyledCard backgroundColor={app.color}>
                <StyledCardActionArea>
                    <StyledCardAppIcon>
                        {getAppIcon(app.icon, theme.palette.getContrastText(app.color), 60)}
                    </StyledCardAppIcon>
                    <StyledCardAppName color={theme.palette.getContrastText(app.color)}>
                        <Typography variant="caption" data-testid="installed-application-name">
                            {app.name}
                        </Typography>
                    </StyledCardAppName>
                </StyledCardActionArea>
                {layer}
            </StyledCard>
            {shareDialogOpen && <ShareDialog onClose={handleShareDialogClose} app={app} />}
            {changeVersionDialogOpen && <ChangeVersionDialog onClose={handleChangeVersionDialogClose} app={app} />}
            <Dialog open={confirmDeletion} onClose={closeConfirmDelete}>
                <DialogTitle>{i18n.tc('dialog.areYouSure')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {app.name}
                        {i18n.tc('dialog.confirmDelete')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={closeConfirmDelete} color="secondary">
                        {i18n.tc('dialog.no')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={triggerRemoveApplication}>
                        {i18n.tc('dialog.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

Application.propTypes = {
    app: PropTypes.shape({
        id: PropTypes.string.isRequired,
        reachable: PropTypes.bool.isRequired,
        latestVersion: PropTypes.object.isRequired,
        color: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        productsTarget: PropTypes.array.isRequired,
    }).isRequired,
    state: PropTypes.string.isRequired,
};

export default Application;
