import { useI18n } from '@braincube/i18n';
import { EmptyContentMessage, advancedTextSearch } from '@braincube/ui';
import { Box, CircularProgress } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import Application from 'components/Content/components/InstalledApplications/components/Application';
import DigitalAssistant from 'components/Content/components/InstalledApplications/components/DigitalAssistant';
import { DA_CATEGORY } from 'services/constants/categories';
import { useFilters } from 'services/context/Filters';
import useApplications from 'services/hooks/react-query/useApplications';
import useInstalledDigitalAssistants from 'services/hooks/react-query/useInstalledDigitalAssistants';
import useProductInfo from 'services/hooks/react-query/useProductInfo';
import { useHeader } from 'services/hooks/useHeader';

function InstalledApplications() {
    const i18n = useI18n();
    const { selectedProductUuid } = useHeader();

    const { textFilter, categoryFilter } = useFilters();

    const { data: apps = [], isLoading: isFetchingApps } = useApplications(selectedProductUuid);
    const { data: digitalAssistants = [], isLoading: isFetchingDigitalAssistants } =
        useInstalledDigitalAssistants(selectedProductUuid);
    const { data: productInfo, isLoading: isFetchingProductInfo } = useProductInfo(selectedProductUuid);

    const getAppProduct = useCallback(
        (appId) => productInfo.apps.find((productApp) => productApp.appId === appId),
        [productInfo],
    );

    const displayedApplications = useMemo(() => {
        return apps.filter((app) => !!getAppProduct(app.id));
    }, [apps, getAppProduct]);

    const finalAppList = useMemo(() => {
        const allApps = [...displayedApplications, ...digitalAssistants];

        return advancedTextSearch(textFilter, allApps, ['name'])
            .filter((app) => {
                return categoryFilter === 'all' || app.category === categoryFilter;
            })
            .sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
    }, [displayedApplications, digitalAssistants, textFilter, categoryFilter]);

    const isLoading = useMemo(
        () => isFetchingApps || isFetchingProductInfo || isFetchingDigitalAssistants,
        [isFetchingApps, isFetchingProductInfo, isFetchingDigitalAssistants],
    );

    if (isLoading) {
        return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="inherit" />
            </Box>
        );
    }

    if (finalAppList.length === 0) {
        return textFilter ? (
            <EmptyContentMessage
                type="NO_RESULT"
                title={i18n.tc('allApplication.noResult.title')}
                subtitle={i18n.tc('allApplication.noResult.subtitle')}
            />
        ) : (
            <EmptyContentMessage
                type="NO_DATA"
                title={i18n.tc('allApplication.noData.title')}
                subtitle={i18n.tc('allApplication.noData.subtitle')}
            />
        );
    }

    return finalAppList.map((app) => {
        if (app.category === DA_CATEGORY) {
            return <DigitalAssistant key={app.id} digitalAssistant={app} />;
        }

        const appProduct = getAppProduct(app.id);

        return (
            <Application
                data-testid="installed-application"
                key={app.id}
                state={appProduct.state}
                versionUuid={appProduct.id}
                app={{ ...app, versionNumber: appProduct.versionNumber }}
            />
        );
    });
}

export default InstalledApplications;
