import { fetchWithBearer } from '@braincube/jwt-browser';
import { getSsoPage } from 'services/helpers';
import { DA_template_mock } from './constantMock';

export async function getUsers() {
    const response = await fetchWithBearer(`https://${getSsoPage()}/sso-server/api/v2/user`);

    if (response.ok) {
        const data = await response.json();
        return data.users;
    }
    return Promise.reject();
}

export async function getApplications(productUuid) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apps`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getCustomApplication(productUuid) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apps/custom`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getApplicationVersions(applicationId) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${applicationId}/version`,
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getApplicationPermissions(appId, productUuid) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/products/${productUuid}/share`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function updateApplicationPermissions(appId, productUuid, share) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/products/${productUuid}/share`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
                'Content-Type': 'application/json',
            }),
            method: 'PUT',
            body: JSON.stringify(share),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getOffers(productUuid) {
    const response = await fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/offers`, {
        headers: new Headers({
            'x-product-id': productUuid,
        }),
    });

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getProductInfo(productUuid) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/info`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function updateProductApplication(productUuid, body) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apply`,
        {
            method: 'POST',
            headers: new Headers({
                'x-product-id': productUuid,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(body),
        },
    );

    if (response.ok) {
        return Promise.resolve();
    }
    return Promise.reject();
}

export async function getDigitalAssistantTemplates(productUuid) {
    // TODO: Remove mocks when endpoint is OK
    // const mock = [
    //     {
    //         id: 'template-1',
    //         iotOwnerId: 'owner-1',
    //         name: 'My wonderful template',
    //         description: 'Blablabla bla bla blablabla bli blou bla blou blaaaaaaaa blub blob bla',
    //         color: '#34dc42',
    //         icon: 'AutoAwesomeMotion',
    //         tenantId: '7eda9bd9-8558-40ea-b411-f13a924fb6c4',
    //         createdAt: '2023-01-01T00:00:00Z',
    //         updatedAt: '2023-01-02T00:00:00Z',
    //     },
    //     {
    //         id: 'template-2',
    //         iotOwnerId: 'owner-1',
    //         name: 'Christophe 😘',
    //         description: "Je suis Christophe, ton assistant personnel (C'est Copilot qui a proposé cette description)",
    //         color: '#34dc42',
    //         icon: 'AutoAwesomeMotion',
    //         tenantId: '7eda9bd9-8558-40ea-b411-f13a924fb6c4',
    //         createdAt: '2023-01-01T00:00:00Z',
    //         updatedAt: '2023-01-02T00:00:00Z',
    //     },
    // ];

    // return Promise.resolve(mock);

    const response = await fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/danm-api/templates`, {
        headers: new Headers({
            'x-product-id': productUuid,
        }),
    });

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getDigitalAssistantTemplate(productUuid, digitalAssistantTemplateId) {
    // TODO: Remove mocks when endpoint is OK
    const mock = DA_template_mock;

    return Promise.resolve(mock);

    // const response = await fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/danm-api/templates/${digitalAssistantTemplateId}`, {
    //     headers: new Headers({
    //         'x-product-id': productUuid,
    //     }),
    // });
    //
    // if (response.ok) {
    //     return response.json();
    // }
    // return Promise.reject();
}

export async function getInstalledDigitalAssistants(productUuid) {
    // TODO: Remove mocks when endpoint is OK
    // const mock = [
    //     {
    //         id: '123e4567-e89b-12d3-a456-426614174000',
    //         name: 'Plop',
    //         description: 'Restons polis',
    //         color: '#34dc42',
    //         icon: 'AutoAwesomeMotion',
    //         createdAt: '2025-01-16T14:44:55.000Z',
    //         updatedAt: '2025-01-16T14:44:55.000Z',
    //         location: {
    //             id: '73c1928b-d418-11ef-a249-0242ac120002',
    //             daId: '73c04c1d-d418-11ef-a249-0242ac120002',
    //             daVersionId: '73c12bff-d418-11ef-a249-0242ac120002',
    //             iotOwnerId: '73c1930e-d418-11ef-a249-0242ac120002',
    //             createdAt: '2025-01-16T14:44:55.000Z',
    //             updatedAt: '2025-01-16T14:44:55.000Z',
    //         },
    //     },
    // ];

    // return Promise.resolve(mock);

    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/danm-api/products/${productUuid}/digital-assistants`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );
    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getDigitalAssistant(productUuid, digitalAssistantId) {
    // TODO: Remove mocks when endpoint is OK
    const mock = {
        id: '123e4567-e89b-12d3-a456-426614174000',
        name: 'Plop',
        description: 'Restons polis',
        color: '#34dc42',
        icon: 'AutoAwesomeMotion',
        createdAt: '2025-01-16T14:44:55.000Z',
        updatedAt: '2025-01-16T14:44:55.000Z',
        location: {
            id: '73c1928b-d418-11ef-a249-0242ac120002',
            daId: '73c04c1d-d418-11ef-a249-0242ac120002',
            daVersionId: '73c12bff-d418-11ef-a249-0242ac120002',
            iotOwnerId: '73c1930e-d418-11ef-a249-0242ac120002',
            createdAt: '2025-01-16T14:44:55.000Z',
            updatedAt: '2025-01-16T14:44:55.000Z',
        },
    };

    return Promise.resolve(mock);

    // const response = await fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/danm-api/digital-assistants/${digitalAssistantId}`, {
    //     headers: new Headers({
    //         'x-product-id': productUuid,
    //     }),
    // });
    //
    // if (response.ok) {
    //     return response.json();
    // }
    // return Promise.reject();
}

export async function deleteDigitalAssistant(productUuid, digitalAssistantId) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/danm-api/products/${productUuid}/digital-assistants/${digitalAssistantId}`,
        {
            method: 'DELETE',
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return Promise.resolve();
    }
    return Promise.reject();
}
