import { useI18n } from '@braincube/i18n';
import tracker from '@braincube/tracker';
import { Download } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';

import { compareVersions, sortAvailableVersions } from './services';

import useApplicationVersions from 'services/hooks/react-query/useApplicationVersions';
import { useHeader } from 'services/hooks/useHeader';

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-scrollPaper': {
        overflow: 'visible',
    },
    minWidth: '600px',
});

const StyledDialogContent = styled(DialogContent)({
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
});

const dialogTitleStyle = { fontWeight: 'bold' };

const StyledLoaderWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const actualVersionStyle = {
    fontWeight: 'bold',
};

function ChangeVersionDialog({ onClose, app }) {
    const i18n = useI18n();
    const [isDowngrading, setIsDowngrading] = useState(false);
    const [selectedVersionNumber, setSelectedVersionNumber] = useState(app.versionNumber);
    const { user, selectedProductUuid } = useHeader();

    const {
        data: versions,
        isLoading: isVersionsLoading,
        isSuccess: isVersionsSucceed,
    } = useApplicationVersions(app.id);

    const sortedVersions = useMemo(() => (versions ? sortAvailableVersions(versions) : []), [versions]);
    const lastAvailableVersionNumber = useMemo(
        () => (sortedVersions && sortedVersions.length > 0 ? sortedVersions[0].versionNumber : []),
        [sortedVersions],
    );
    const installedVersionNumber = useMemo(() => app.versionNumber, [app.versionNumber]);

    const isInstalledVersionSelected = useMemo(() => {
        return selectedVersionNumber === installedVersionNumber;
    }, [selectedVersionNumber, installedVersionNumber]);

    const closeDialogWithoutValue = useCallback(() => {
        onClose(false);
    }, [onClose]);

    const changeVersion = useCallback(() => {
        if (isDowngrading) {
            tracker.metriks.pushMetrics('app_store_front_version_downgrade', 1, {
                metricsType: tracker.MetricsTypes.Gauge,
                labelsMap: {
                    app: app.name,
                    appId: app.id,
                    selectedProductUuid,
                    from: installedVersionNumber,
                    to: selectedVersionNumber,
                    userFullName: user.fullName,
                    userUuid: user.uuid,
                },
            });
        }
        const versionUuid = sortedVersions.find((version) => version.versionNumber === selectedVersionNumber).id;
        onClose(versionUuid);
    }, [
        selectedVersionNumber,
        installedVersionNumber,
        isDowngrading,
        sortedVersions,
        selectedProductUuid,
        app,
        user,
        onClose,
    ]);

    const getVersionStyle = useCallback(
        (versionNumber) => ({
            fontWeight: installedVersionNumber === versionNumber ? 'bold' : 'normal',
        }),
        [installedVersionNumber],
    );

    const handleChange = useCallback(
        (event) => {
            const versionNumberEvent = event.target.value;

            // Check if the selected version is a downgrade and if it's the case, set the state to display the downgrading message
            setIsDowngrading(compareVersions(versionNumberEvent, installedVersionNumber) < 0);
            setSelectedVersionNumber(event.target.value);
        },
        [installedVersionNumber],
    );

    return (
        <StyledDialog open onClose={closeDialogWithoutValue} fullWidth data-testid="change-version-dialog">
            <DialogTitle>
                <Box display="flex" alignItems="center" gap="10px">
                    <Download />
                    <Typography variant="h6" style={dialogTitleStyle} data-testid="change-version-dialog-title">
                        {i18n.tc('dialog.manageVersionDialog.title')} - {app.name}
                    </Typography>
                </Box>
            </DialogTitle>
            <StyledDialogContent>
                {isVersionsLoading && (
                    <StyledLoaderWrapper>
                        <CircularProgress />
                        <Typography paragraph>{i18n.tc('dialog.loading')}</Typography>
                    </StyledLoaderWrapper>
                )}
                {isVersionsSucceed && (
                    <>
                        <Typography variant="body2" display="flex" flexDirection="column" gap="15px" component="div">
                            <Alert severity="info" data-testid="change-version-dialog-info">
                                {i18n.tc('dialog.manageVersionDialog.versionSupportInfo')}
                            </Alert>
                            {isDowngrading && (
                                <Alert severity="warning" data-testid="change-version-dialog-downgrade-warning">
                                    {i18n.tc('dialog.manageVersionDialog.versionDowngradeWarning')}
                                </Alert>
                            )}
                            {!isDowngrading && installedVersionNumber !== lastAvailableVersionNumber && (
                                <Alert severity="warning" data-testid="change-version-dialog-not-last-version-warning">
                                    {i18n.tc('dialog.manageVersionDialog.notLastVersionWarning')}
                                </Alert>
                            )}
                        </Typography>
                        {sortedVersions && (
                            <Box display="flex" flexDirection="column" gap="15px">
                                <Select
                                    value={selectedVersionNumber}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    data-testid="change-version-dialog-select"
                                >
                                    {sortedVersions.map((version) => (
                                        <MenuItem key={version.id} value={version.versionNumber}>
                                            <span style={getVersionStyle(version.versionNumber)}>
                                                {version.versionNumber}
                                            </span>
                                            {installedVersionNumber === version.versionNumber && (
                                                <span style={actualVersionStyle}>
                                                    &nbsp;({i18n.tc('dialog.manageVersionDialog.actualVersion')}
                                                    {installedVersionNumber === lastAvailableVersionNumber && (
                                                        <span>
                                                            {' '}
                                                            / {i18n.tc('dialog.manageVersionDialog.lastVersion')}
                                                        </span>
                                                    )}
                                                    )
                                                </span>
                                            )}
                                            {version.versionNumber === lastAvailableVersionNumber &&
                                                installedVersionNumber !== lastAvailableVersionNumber && (
                                                    <span>
                                                        &nbsp;({i18n.tc('dialog.manageVersionDialog.lastVersion')})
                                                    </span>
                                                )}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        )}
                    </>
                )}
            </StyledDialogContent>
            {isVersionsSucceed && (
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={closeDialogWithoutValue}
                        data-testid="change-version-dialog-cancel-button"
                        color="secondary"
                    >
                        {i18n.tc('common.cancel')}
                    </Button>
                    <Tooltip
                        title={
                            isInstalledVersionSelected
                                ? i18n.tc('dialog.manageVersionDialog.disabledButtonTooltip')
                                : ''
                        }
                        placement="top"
                    >
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isInstalledVersionSelected}
                                onClick={changeVersion}
                                data-testid="change-version-dialog-install-button"
                            >
                                {i18n.tc('common.install')}
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            )}
        </StyledDialog>
    );
}

ChangeVersionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    app: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        versionNumber: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
    }),
};

ChangeVersionDialog.defaultProps = {
    user: {
        fullName: 'unknown',
        uuid: 'unknown',
    },
};

export default ChangeVersionDialog;
