import { TextField } from '@mui/material';
import fieldPropType from 'components/App/components/AppHeader/components/DigitalAssistantCreator/components/Stepper/components/PropertiesStep/services/constants';
import { useFormikContext } from 'formik';
import React, { useState, useMemo } from 'react';

import PasswordDisplayToggle from './PasswordDisplayToggle';

function StringField({ property }) {
    const { values, errors, handleChange } = useFormikContext();

    const [showPassword, onPasswordToggle] = useState(false);

    const InputProps = useMemo(
        () => ({
            endAdornment: property.required ? (
                <PasswordDisplayToggle showPassword={showPassword} onPasswordToggle={onPasswordToggle} />
            ) : null,
        }),
        [property.required, showPassword],
    );

    return (
        <TextField
            fullWidth
            name={`['${property.id}']`}
            label={property.label}
            placeholder={property.default}
            required
            value={values[property.id]}
            onChange={handleChange}
            error={!!errors[property.id]}
            helperText={errors[property.id]}
            type={property.required && !showPassword ? 'password' : 'text'}
            InputProps={InputProps}
        />
    );
}

StringField.propTypes = fieldPropType;

export default StringField;
