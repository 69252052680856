import { HeaderActions, HeaderMenu, HeaderWidgets } from '@braincube/header';
import { Assistant } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import DigitalAssistantCreator from 'components/App/components/AppHeader/components/DigitalAssistantCreator';
import Menu from 'components/App/components/Menu';
import React, { useCallback, useState } from 'react';
import useFeatureAccess from 'services/hooks/useFeatureAccess';
import { DigitalAssistantCreatorContextProvider } from './components/DigitalAssistantCreator/context';

function AppHeader() {
    const { hasAccess: hasAccessToDigitalAssistant } = useFeatureAccess('appStore.digitalAssistantAvailable');

    const [digitalAssistantCreatorOpened, setDigitalAssistantCreatorOpened] = useState(false);

    const openDigitalAssistantCreator = useCallback(() => setDigitalAssistantCreatorOpened(true), []);

    const closeDigitalAssistantCreator = useCallback(() => setDigitalAssistantCreatorOpened(false), []);

    return (
        <>
            <HeaderActions>
                {hasAccessToDigitalAssistant && (
                    <Tooltip title="Digital Assistant Creator" placement="bottom">
                        <IconButton
                            color="inherit"
                            onClick={openDigitalAssistantCreator}
                            data-testid="open-digital-assistant"
                        >
                            <Assistant />
                        </IconButton>
                    </Tooltip>
                )}
                <HeaderWidgets />
                <HeaderMenu>
                    <Menu />
                </HeaderMenu>
            </HeaderActions>

            {hasAccessToDigitalAssistant && (
                <DigitalAssistantCreatorContextProvider>
                    <DigitalAssistantCreator
                        opened={digitalAssistantCreatorOpened}
                        onClose={closeDigitalAssistantCreator}
                    />
                </DigitalAssistantCreatorContextProvider>
            )}
        </>
    );
}

export default AppHeader;
