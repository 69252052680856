import { Edgebox, Factory } from '@braincube/svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LinearProgress, Popover, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHeader } from 'services/hooks/useHeader';
import { get, set } from './cookies';

const COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID = 'braincube-header-environment-uuid';

const StyledListItem = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:hover': {
        backgroundColor: '#FDE000',
    },
}));

const StyledListItemRightSite = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

const StyledCurrent = styled('div')(({ theme }) => ({
    margin: theme.spacing(0, 1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2.5),
        flex: 1,
        overflow: 'hidden',
    },
}));

const StyledList = styled('ul')({
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    maxHeight: 300,
});

const StyledContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'isSingleAccess' })(
    ({ theme, isSingleAccess }) => ({
        cursor: isSingleAccess ? 'default' : 'pointer',
        display: 'flex',
        backgroundColor: theme.palette.grey[200],
        borderRight: `1px solid ${theme.palette.grey[500]}`,
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        height: theme.header.height,
        flex: 1,
        maxWidth: 340,
        [theme.breakpoints.down('sm')]: {
            flex: 'initial',
            flexShrink: 0,
            borderRight: 'none',
            paddingLeft: theme.spacing(2),
        },
    }),
);

const ProductItem = ({ access, onChange }) => {
    const triggerChange = useCallback(() => onChange(access), [access, onChange]);

    return (
        <StyledListItem key={access.product.productId} onClick={triggerChange}>
            {access.product.type === 'braincube' ? <Factory /> : <Edgebox />}
            <StyledListItemRightSite>
                <Typography variant={access.product.type === 'iot' ? 'body2' : 'body1'} noWrap>
                    {access.product.name}
                </Typography>
                {access.product.type === 'iot' && (
                    <Typography variant="caption" noWrap>
                        {`SN: ${access.product.productId}`}
                    </Typography>
                )}
            </StyledListItemRightSite>
        </StyledListItem>
    );
};

ProductItem.propTypes = {
    access: PropTypes.shape({
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            productId: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

const popoverProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

const ProductSelector = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { accessList, braincubeProduct, selectedProductName, selectedProductUuid, setSelectedProduct } = useHeader();

    const closePopover = useCallback(() => setAnchorEl(null), []);

    const isSingleAccess = useMemo(() => accessList.length === 1, [accessList]);

    const openProductSelector = useCallback(
        (event) => (isSingleAccess ? null : setAnchorEl(event.currentTarget)),
        [isSingleAccess],
    );

    const filteredAccessList = useMemo(
        () => accessList.filter((access) => ['braincube', 'iot', 'plateform'].includes(access.product.type)),
        [accessList],
    );

    useEffect(() => {
        const parameters = new URLSearchParams(window.location.search);
        const uuidUrlParameter = parameters.get('productUuid');
        const cookie = get(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID);

        if (uuidUrlParameter) {
            const finded = filteredAccessList.find((access) => access.product.productId === uuidUrlParameter);

            if (finded) {
                set(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID, finded.product.productId);
                setSelectedProduct(finded.product);
            } else {
                set(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID, filteredAccessList[0].product.productId);
                setSelectedProduct(filteredAccessList[0].product);
            }
        } else {
            const finded = filteredAccessList.find((access) => access.product.productId === cookie);

            if (finded) {
                setSelectedProduct(finded.product);
            } else {
                set(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID, filteredAccessList[0].product.productId);
                setSelectedProduct(filteredAccessList[0].product);
            }
        }
    }, [setSelectedProduct, filteredAccessList]);

    const changeProduct = useCallback(
        (access) => {
            closePopover();

            set(COOKIE_BRAINCUBE_HEADER_ENVIRONMENT_UUID, access.product.productId);
            setSelectedProduct(access.product);

            const url = new URL(window.location.href);
            url.searchParams.set('productUuid', access.product.productId);
            url.searchParams.set('siteUuid', access.product.site.siteUuid);
            window.history.replaceState({}, '', url.toString());
        },
        [setSelectedProduct, closePopover],
    );

    return (
        <>
            <StyledContainer isSingleAccess={isSingleAccess} onClick={openProductSelector}>
                {braincubeProduct?.type === 'braincube' ? <Factory /> : <Edgebox />}

                <StyledCurrent>
                    {!braincubeProduct && <LinearProgress color="secondary" />}

                    {braincubeProduct && (
                        <>
                            <Typography variant="subtitle2" noWrap>
                                {selectedProductName}
                            </Typography>
                            {braincubeProduct.type === 'iot' && (
                                <Typography variant="caption" noWrap>
                                    {`SN: ${selectedProductUuid}`}
                                </Typography>
                            )}
                        </>
                    )}
                </StyledCurrent>
                {!isSingleAccess && <KeyboardArrowDownIcon />}
            </StyledContainer>

            {braincubeProduct && (
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={popoverProps.anchorOrigin}
                    transformOrigin={popoverProps.transformOrigin}
                    marginThreshold={0}
                >
                    <StyledList>
                        {accessList
                            .sort((a, b) => {
                                return a.product.name.trim().localeCompare(b.product.name.trim());
                            })
                            .filter((access) => access.product.productId !== selectedProductUuid)
                            .map((access) => (
                                <ProductItem key={access.product.productId} access={access} onChange={changeProduct} />
                            ))}
                    </StyledList>
                </Popover>
            )}
        </>
    );
};

export default ProductSelector;
