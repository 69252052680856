import { useQuery } from '@tanstack/react-query';
import { getCustomApplication } from 'services/api';
import { RQ_KEY_CUSTOM_APPLICATIONS } from 'services/constants/reactQuery';

function useCustomApplications(applicationId) {
    return useQuery({
        queryKey: [RQ_KEY_CUSTOM_APPLICATIONS, { applicationId }],
        queryFn: () => getCustomApplication(applicationId),
        enabled: !!applicationId,
    });
}

export default useCustomApplications;
