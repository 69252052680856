import { useQuery } from '@tanstack/react-query';

import { getInstalledDigitalAssistants } from 'services/api';
import { DA_CATEGORY } from 'services/constants/categories';
import { RQ_KEY_INSTALLED_DA } from 'services/constants/reactQuery';
import useFeatureAccess from 'services/hooks/useFeatureAccess';

function useInstalledDigitalAssistants(productUuid) {
    const { hasAccess: hasAccessToDigitalAssistant = false } = useFeatureAccess('appStore.digitalAssistantAvailable');

    return useQuery({
        queryKey: [RQ_KEY_INSTALLED_DA, { productUuid }],
        queryFn: () => getInstalledDigitalAssistants(productUuid),
        select: (data) => data.map((digitalAssistant) => ({ ...digitalAssistant, category: DA_CATEGORY })),
        enabled: hasAccessToDigitalAssistant && !!productUuid,
        // TODO: Add retry logic when the endpoint is ready in preproduction
        retry: 0,
    });
}

export default useInstalledDigitalAssistants;
