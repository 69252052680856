import * as icons from '@braincube/svg';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import React from 'react';

export function getSsoPage() {
    if (
        window.location.hostname.includes('.blu-e.fr') &&
        Object.prototype.hasOwnProperty.call(window.BC_API_ENDPOINTS_CONF, 'baseBlue')
    ) {
        return window.BC_API_ENDPOINTS_CONF.baseBlue;
    }
    if (Object.prototype.hasOwnProperty.call(window.BC_API_ENDPOINTS_CONF, 'sso')) {
        return window.BC_API_ENDPOINTS_CONF.sso;
    }
    return window.BC_API_ENDPOINTS_CONF.base;
}

export const getAppIcon = (icon, logoColor) => {
    const allIcons = { ...icons, AutoAwesomeMotionIcon: AutoAwesomeMotionIcon };

    const logoComponent = Object.keys(allIcons).filter((logo) => {
        return logo.replace('Icon', '') === icon && logo !== 'default';
    });

    let logo = null;

    if (logoComponent.length > 0) {
        logo = React.createElement(allIcons[logoComponent[0]], { fontSize: 'inherit', htmlColor: logoColor });
    } else {
        logo = <icons.Node fontSize="inherit" htmlColor={logoColor} />;
    }

    return logo;
};
