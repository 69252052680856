import { useI18n } from '@braincube/i18n';
import { Grid, Stack } from '@mui/material';
import React from 'react';

import { SelectedAppProvider } from 'services/context/SelectedAppProvider';

import SectionTitle from 'components/App/components/SectionTitle';
import AppDetail from './components/AppDetail';
import AvailableApplications from './components/AvailableApplications';
import InstalledApplications from './components/InstalledApplications';

const ContentComponent = () => {
    const i18n = useI18n();

    return (
        <SelectedAppProvider>
            <div>
                <Stack spacing={2} pt={8} pb={2}>
                    <div>
                        <SectionTitle title={i18n.tc('allApplication.installed.title')} />
                        <Grid container spacing={2} sx={{ px: 2 }} data-testid="installed-application-grid">
                            <InstalledApplications />
                        </Grid>
                    </div>
                    <div>
                        <SectionTitle title={i18n.tc('allApplication.title')} />
                        <Grid container spacing={2} sx={{ px: 2 }}>
                            <AvailableApplications />
                        </Grid>
                    </div>
                </Stack>
                <AppDetail />
            </div>
        </SelectedAppProvider>
    );
};

export default ContentComponent;
