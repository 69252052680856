import { useI18n } from '@braincube/i18n';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, MobileStepper, styled, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

const StyledRoot = styled('div')({
    flexGrow: 1,
});

const StyledImage = styled('img')({
    height: 255,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
});

const getMediaUrl = (url) => {
    return url
        .replace(/{CDN}\//g, '{CDN}') // make work {CDN}/url or {CDN}url
        .replace(/https:\/\/{CDN}/g, '{CDN}') // make work https://{CDN}/url or {CDN}/url
        .replace(/http:\/\/{CDN}/g, '{CDN}') // make work http://{CDN}/url or https://{CDN}/url
        .replace(/{CDN}/g, `https://${window.BC_API_ENDPOINTS_CONF.cdn}/`);
};

const StepperButton = ({ onClick, disabled, type }) => {
    const i18n = useI18n();
    const theme = useTheme();
    const label = type === 'next' ? i18n.tc('mediaStepper.next') : i18n.tc('mediaStepper.back');

    const displayButtons = useMemo(() => {
        if (type === 'next') {
            return theme.direction === 'rtl' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />;
        }

        if (type === 'back') {
            return theme.direction === 'rtl' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />;
        }

        return '';
    }, [theme, type]);

    return (
        <Button size="small" onClick={onClick} disabled={disabled} color="inherit">
            {label}
            {displayButtons}
        </Button>
    );
};

StepperButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['next', 'back']).isRequired,
};

StepperButton.defaultProps = {
    disabled: false,
};

const MediaStepper = ({ medias }) => {
    const [activeStep, setActiveStep] = useState(0);

    const maxSteps = useMemo(() => medias.length, [medias.length]);

    const handleNext = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep + 1), []);

    const handleBack = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep - 1), []);

    const renderNextButton = useMemo(
        () => <StepperButton onClick={handleNext} type="next" disabled={activeStep === maxSteps - 1} />,
        [activeStep, handleNext, maxSteps],
    );

    const renderBackButton = useMemo(
        () => <StepperButton onClick={handleBack} type="back" disabled={activeStep === 0} />,
        [activeStep, handleBack],
    );

    return (
        <StyledRoot>
            {medias[activeStep].endsWith('.mp4') ? (
                // biome-ignore lint/a11y/useMediaCaption: <explanation>
                <video controls width="100%">
                    <source src={getMediaUrl(medias[activeStep])} />
                </video>
            ) : (
                <StyledImage src={getMediaUrl(medias[activeStep])} alt={getMediaUrl(medias[activeStep])} />
            )}
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={renderNextButton}
                backButton={renderBackButton}
            />
        </StyledRoot>
    );
};

MediaStepper.propTypes = {
    medias: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MediaStepper;
