import { useQuery } from '@tanstack/react-query';
import { getApplicationPermissions } from 'services/api';
import { RQ_KEY_APP_PERMISSIONS } from 'services/constants/reactQuery';

function useApplicationPermissions(applicationId, productUuid) {
    return useQuery({
        queryKey: [RQ_KEY_APP_PERMISSIONS, { applicationId, productUuid }],
        queryFn: () => getApplicationPermissions(applicationId, productUuid),
        enabled: !!applicationId && !!productUuid,
    });
}

export default useApplicationPermissions;
