import { useQuery } from '@tanstack/react-query';
import { getOffers } from 'services/api';
import { RQ_KEY_OFFERS } from 'services/constants/reactQuery';

function useOffers(productUuid) {
    return useQuery({
        queryKey: [RQ_KEY_OFFERS, { productUuid }],
        queryFn: () => getOffers(productUuid),
        enabled: !!productUuid,
    });
}

export default useOffers;
