import { CircularProgress, styled } from '@mui/material';
import React from 'react';

import Content from 'components/Content';
import Filters from 'components/Filters';
import { FiltersProvider } from 'services/context/Filters';
import { useWebSocket } from 'services/context/WebSocket';
import useOffers from 'services/hooks/react-query/useOffers';
import useProductInfo from 'services/hooks/react-query/useProductInfo';
import { useHeader } from 'services/hooks/useHeader';

const StyledContainer = styled('div')({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
});

const StyledRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: '100vh',
});

const App = () => {
    const { stompClient } = useWebSocket();
    const { accessList, selectedProductUuid } = useHeader();
    const { data: productInfo, isLoading: isLoadingProductInfo } = useProductInfo(selectedProductUuid);
    const { data: offers, isLoading: isLoadingOffers } = useOffers(selectedProductUuid);

    if (
        isLoadingProductInfo ||
        isLoadingOffers ||
        accessList.length === 0 ||
        !selectedProductUuid ||
        stompClient === null
    ) {
        return (
            <StyledContainer>
                <CircularProgress color="inherit" />
            </StyledContainer>
        );
    }
    if (accessList.length > 0 && selectedProductUuid && productInfo && offers && stompClient) {
        return (
            <StyledRoot>
                <FiltersProvider>
                    <Filters />
                    <Content />
                </FiltersProvider>
            </StyledRoot>
        );
    }
    return null;
};

export default App;
