import { useMemo } from 'react';

import { useHeader } from 'services/hooks/useHeader';

export default function useIsSelectedBoxIoT() {
    const { accessList, selectedProductName } = useHeader();

    return useMemo(
        () => accessList.find(({ product }) => product.name === selectedProductName).product.type === 'iot',
        [accessList, selectedProductName],
    );
}
